import { default as _91slug_93DA9FW8NqVuMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue?macro=true";
import { default as _91slug_930haBteEH6UMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue?macro=true";
import { default as _91slug_93N4EsnmmhYhMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue?macro=true";
import { default as _91slug_93kgJFivaVD5Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue?macro=true";
import { default as _91slug_933igYnclg0JMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue?macro=true";
import { default as _91slug_93I6owR6PKtNMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue?macro=true";
import { default as _91slug_93yRYkixP4npMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue?macro=true";
import { default as _91slug_931ki4nWQCaOMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue?macro=true";
import { default as _91slug_93QCT2fZpEQFMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue?macro=true";
import { default as _91slug_93HLYeM6G8aXMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue?macro=true";
import { default as _91slug_938C9fojgsUKMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue?macro=true";
import { default as indexgvA1D7XlOXMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue?macro=true";
import { default as _91slug_93KMEh6bH4STMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue?macro=true";
import { default as _91slug_93kiPAjKPEcjMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue?macro=true";
import { default as _91slug_93xNYYrRLxLEMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue?macro=true";
import { default as _91slug_93e9tEwk5C1YMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue?macro=true";
import { default as _91slug_93aKgRVohlVHMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue?macro=true";
import { default as indexnVVEiZSRAoMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue?macro=true";
import { default as _91slug_93z7qcujcSq0Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue?macro=true";
import { default as indexdAr8D3NQEmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue?macro=true";
import { default as _91slug_93rqUDHMUQ9XMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue?macro=true";
import { default as _91slug_939DgOHOzkTuMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue?macro=true";
import { default as _91slug_93wzcuBDb5xDMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue?macro=true";
import { default as _91slug_93ZE2P7gqYzTMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue?macro=true";
import { default as index84hEBuWar7Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue?macro=true";
import { default as _91slug_93Ukxj2XB8ouMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue?macro=true";
import { default as _91slug_93nwc3jeXoF5Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue?macro=true";
import { default as _91slug_93eLzJ1UrZEEMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue?macro=true";
import { default as _91slug_93k9pqAuqaBHMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue?macro=true";
import { default as _91slug_939VMILdIglmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue?macro=true";
import { default as _91slug_93dnN11SISnmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue?macro=true";
import { default as _91slug_93OCUFwik12uMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue?macro=true";
import { default as _91slug_93f1pHAaoAVJMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue?macro=true";
import { default as _91slug_93NOTbVW6CBjMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue?macro=true";
import { default as _91slug_93Mh1wwNDrnbMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue?macro=true";
import { default as _91slug_93wSSz3oeHbZMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue?macro=true";
import { default as _91slug_93ArLowJD3sPMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue?macro=true";
import { default as _91slug_93YnNndc1mmUMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue?macro=true";
import { default as _91slug_93fIV7Bm18RAMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue?macro=true";
import { default as _91slug_93wUh2thKaIlMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue?macro=true";
import { default as indexeGW3ihEjAhMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue?macro=true";
import { default as _91slug_93HgQsW7PPxEMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue?macro=true";
import { default as _91slug_93f59XN2OWKtMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue?macro=true";
import { default as indexvLIquZIOLmMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue?macro=true";
import { default as _91type_93XE7y2m9KOIMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue?macro=true";
import { default as index1bZqJZeZFHMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue?macro=true";
import { default as _91slug_93pDTNvFP9uJMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue?macro=true";
import { default as _91slug_93ikH2wA01dLMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue?macro=true";
import { default as _91slug_star_93_45starbDHMbh0DQFMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue?macro=true";
import { default as facility_45_91slug_facility_93sQgbkerZ7pMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue?macro=true";
import { default as group_45_91slug_chain_936rramvYVj4Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue?macro=true";
import { default as indexvYoauhrF1EMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue?macro=true";
import { default as type_45_91slug_type_936YzOssuta0Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue?macro=true";
import { default as _91slug_star_93_45starv2hnZzYyDxMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue?macro=true";
import { default as facility_45_91slug_facility_93ebRGcBcCNnMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue?macro=true";
import { default as index3hHlllgPzLMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue?macro=true";
import { default as type_45_91slug_type_93rRRcRD5d8hMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue?macro=true";
import { default as _91slug_star_93_45staroHx3roTXJfMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue?macro=true";
import { default as facility_45_91slug_facility_93wrm7VMFwUZMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue?macro=true";
import { default as indexqWTuit0DAgMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue?macro=true";
import { default as type_45_91slug_type_93OQbdCwCgA2Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue?macro=true";
import { default as _91slug_landmark_93JB6VYDXmw1Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue?macro=true";
import { default as _91slug_star_93_45starw5vBPjKgZYMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue?macro=true";
import { default as facility_45_91slug_facility_937aymqOEvGcMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue?macro=true";
import { default as index6ym2b9qFZ0Meta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue?macro=true";
import { default as type_45_91slug_type_93cxOSIJtP0GMeta } from "/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue?macro=true";
export default [
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___en",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/en/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___id",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/id/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___tl",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/tl/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___zh",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/zh/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___zh-tw",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/zh-tw/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___th",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/th/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ja",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ja/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ms",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ms/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ko",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ko/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___vi",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/vi/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___it",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/it/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___de",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/de/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___es",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/es/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___hi",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/hi/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___fr",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/fr/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ru",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ru/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___bn",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/bn/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___pt",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/pt/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___km",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/km/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___nl",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/nl/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___pl",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/pl/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___tr",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/tr/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___sv",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/sv/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___cs",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/cs/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___nb",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/nb/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ro",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ro/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___el",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/el/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___da",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/da/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___uk",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/uk/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___hr",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/hr/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___ca",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/ca/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___hu",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/hu/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___sk",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/sk/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___fi",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/fi/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___sl",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/sl/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___bg",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/bg/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA9FW8NqVuMeta?.name ?? "airlines-slug_airline-airport-airport-slug___sr",
    path: _91slug_93DA9FW8NqVuMeta?.path ?? "/sr/airlines/:slug_airline()/airport-airport/:slug()",
    meta: _91slug_93DA9FW8NqVuMeta || {},
    alias: _91slug_93DA9FW8NqVuMeta?.alias || [],
    redirect: _91slug_93DA9FW8NqVuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___en",
    path: _91slug_930haBteEH6UMeta?.path ?? "/en/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___id",
    path: _91slug_930haBteEH6UMeta?.path ?? "/id/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___tl",
    path: _91slug_930haBteEH6UMeta?.path ?? "/tl/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___zh",
    path: _91slug_930haBteEH6UMeta?.path ?? "/zh/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___zh-tw",
    path: _91slug_930haBteEH6UMeta?.path ?? "/zh-tw/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___th",
    path: _91slug_930haBteEH6UMeta?.path ?? "/th/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ja",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ja/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ms",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ms/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ko",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ko/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___vi",
    path: _91slug_930haBteEH6UMeta?.path ?? "/vi/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___it",
    path: _91slug_930haBteEH6UMeta?.path ?? "/it/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___de",
    path: _91slug_930haBteEH6UMeta?.path ?? "/de/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___es",
    path: _91slug_930haBteEH6UMeta?.path ?? "/es/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___hi",
    path: _91slug_930haBteEH6UMeta?.path ?? "/hi/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___fr",
    path: _91slug_930haBteEH6UMeta?.path ?? "/fr/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ru",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ru/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___bn",
    path: _91slug_930haBteEH6UMeta?.path ?? "/bn/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___pt",
    path: _91slug_930haBteEH6UMeta?.path ?? "/pt/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___km",
    path: _91slug_930haBteEH6UMeta?.path ?? "/km/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___nl",
    path: _91slug_930haBteEH6UMeta?.path ?? "/nl/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___pl",
    path: _91slug_930haBteEH6UMeta?.path ?? "/pl/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___tr",
    path: _91slug_930haBteEH6UMeta?.path ?? "/tr/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___sv",
    path: _91slug_930haBteEH6UMeta?.path ?? "/sv/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___cs",
    path: _91slug_930haBteEH6UMeta?.path ?? "/cs/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___nb",
    path: _91slug_930haBteEH6UMeta?.path ?? "/nb/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ro",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ro/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___el",
    path: _91slug_930haBteEH6UMeta?.path ?? "/el/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___da",
    path: _91slug_930haBteEH6UMeta?.path ?? "/da/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___uk",
    path: _91slug_930haBteEH6UMeta?.path ?? "/uk/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___hr",
    path: _91slug_930haBteEH6UMeta?.path ?? "/hr/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___ca",
    path: _91slug_930haBteEH6UMeta?.path ?? "/ca/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___hu",
    path: _91slug_930haBteEH6UMeta?.path ?? "/hu/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___sk",
    path: _91slug_930haBteEH6UMeta?.path ?? "/sk/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___fi",
    path: _91slug_930haBteEH6UMeta?.path ?? "/fi/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___sl",
    path: _91slug_930haBteEH6UMeta?.path ?? "/sl/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___bg",
    path: _91slug_930haBteEH6UMeta?.path ?? "/bg/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930haBteEH6UMeta?.name ?? "airlines-slug_airline-airport-city-slug___sr",
    path: _91slug_930haBteEH6UMeta?.path ?? "/sr/airlines/:slug_airline()/airport-city/:slug()",
    meta: _91slug_930haBteEH6UMeta || {},
    alias: _91slug_930haBteEH6UMeta?.alias || [],
    redirect: _91slug_930haBteEH6UMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___en",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/en/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___id",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/id/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___tl",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/tl/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___zh",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/zh/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___zh-tw",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/zh-tw/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___th",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/th/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ja",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ja/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ms",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ms/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ko",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ko/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___vi",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/vi/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___it",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/it/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___de",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/de/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___es",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/es/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___hi",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/hi/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___fr",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/fr/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ru",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ru/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___bn",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/bn/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___pt",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/pt/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___km",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/km/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___nl",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/nl/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___pl",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/pl/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___tr",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/tr/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___sv",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/sv/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___cs",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/cs/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___nb",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/nb/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ro",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ro/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___el",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/el/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___da",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/da/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___uk",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/uk/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___hr",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/hr/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___ca",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/ca/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___hu",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/hu/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___sk",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/sk/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___fi",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/fi/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___sl",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/sl/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___bg",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/bg/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N4EsnmmhYhMeta?.name ?? "airlines-slug_airline-city-airport-slug___sr",
    path: _91slug_93N4EsnmmhYhMeta?.path ?? "/sr/airlines/:slug_airline()/city-airport/:slug()",
    meta: _91slug_93N4EsnmmhYhMeta || {},
    alias: _91slug_93N4EsnmmhYhMeta?.alias || [],
    redirect: _91slug_93N4EsnmmhYhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___en",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/en/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___id",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/id/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___tl",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/tl/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___zh",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/zh/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___zh-tw",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/zh-tw/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___th",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/th/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ja",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ja/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ms",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ms/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ko",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ko/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___vi",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/vi/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___it",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/it/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___de",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/de/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___es",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/es/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___hi",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/hi/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___fr",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/fr/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ru",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ru/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___bn",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/bn/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___pt",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/pt/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___km",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/km/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___nl",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/nl/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___pl",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/pl/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___tr",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/tr/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___sv",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/sv/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___cs",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/cs/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___nb",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/nb/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ro",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ro/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___el",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/el/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___da",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/da/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___uk",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/uk/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___hr",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/hr/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___ca",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/ca/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___hu",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/hu/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___sk",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/sk/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___fi",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/fi/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___sl",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/sl/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___bg",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/bg/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kgJFivaVD5Meta?.name ?? "airlines-slug_airline-city-city-slug___sr",
    path: _91slug_93kgJFivaVD5Meta?.path ?? "/sr/airlines/:slug_airline()/city-city/:slug()",
    meta: _91slug_93kgJFivaVD5Meta || {},
    alias: _91slug_93kgJFivaVD5Meta?.alias || [],
    redirect: _91slug_93kgJFivaVD5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___en",
    path: _91slug_933igYnclg0JMeta?.path ?? "/en/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___id",
    path: _91slug_933igYnclg0JMeta?.path ?? "/id/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___tl",
    path: _91slug_933igYnclg0JMeta?.path ?? "/tl/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___zh",
    path: _91slug_933igYnclg0JMeta?.path ?? "/zh/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___zh-tw",
    path: _91slug_933igYnclg0JMeta?.path ?? "/zh-tw/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___th",
    path: _91slug_933igYnclg0JMeta?.path ?? "/th/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ja",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ja/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ms",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ms/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ko",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ko/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___vi",
    path: _91slug_933igYnclg0JMeta?.path ?? "/vi/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___it",
    path: _91slug_933igYnclg0JMeta?.path ?? "/it/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___de",
    path: _91slug_933igYnclg0JMeta?.path ?? "/de/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___es",
    path: _91slug_933igYnclg0JMeta?.path ?? "/es/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___hi",
    path: _91slug_933igYnclg0JMeta?.path ?? "/hi/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___fr",
    path: _91slug_933igYnclg0JMeta?.path ?? "/fr/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ru",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ru/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___bn",
    path: _91slug_933igYnclg0JMeta?.path ?? "/bn/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___pt",
    path: _91slug_933igYnclg0JMeta?.path ?? "/pt/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___km",
    path: _91slug_933igYnclg0JMeta?.path ?? "/km/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___nl",
    path: _91slug_933igYnclg0JMeta?.path ?? "/nl/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___pl",
    path: _91slug_933igYnclg0JMeta?.path ?? "/pl/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___tr",
    path: _91slug_933igYnclg0JMeta?.path ?? "/tr/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___sv",
    path: _91slug_933igYnclg0JMeta?.path ?? "/sv/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___cs",
    path: _91slug_933igYnclg0JMeta?.path ?? "/cs/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___nb",
    path: _91slug_933igYnclg0JMeta?.path ?? "/nb/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ro",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ro/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___el",
    path: _91slug_933igYnclg0JMeta?.path ?? "/el/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___da",
    path: _91slug_933igYnclg0JMeta?.path ?? "/da/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___uk",
    path: _91slug_933igYnclg0JMeta?.path ?? "/uk/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___hr",
    path: _91slug_933igYnclg0JMeta?.path ?? "/hr/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___ca",
    path: _91slug_933igYnclg0JMeta?.path ?? "/ca/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___hu",
    path: _91slug_933igYnclg0JMeta?.path ?? "/hu/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___sk",
    path: _91slug_933igYnclg0JMeta?.path ?? "/sk/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___fi",
    path: _91slug_933igYnclg0JMeta?.path ?? "/fi/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___sl",
    path: _91slug_933igYnclg0JMeta?.path ?? "/sl/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___bg",
    path: _91slug_933igYnclg0JMeta?.path ?? "/bg/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933igYnclg0JMeta?.name ?? "airlines-slug_airline-city-country-slug___sr",
    path: _91slug_933igYnclg0JMeta?.path ?? "/sr/airlines/:slug_airline()/city-country/:slug()",
    meta: _91slug_933igYnclg0JMeta || {},
    alias: _91slug_933igYnclg0JMeta?.alias || [],
    redirect: _91slug_933igYnclg0JMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___en",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/en/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___id",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/id/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___tl",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/tl/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___zh",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/zh/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___zh-tw",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/zh-tw/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___th",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/th/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ja",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ja/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ms",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ms/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ko",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ko/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___vi",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/vi/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___it",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/it/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___de",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/de/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___es",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/es/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___hi",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/hi/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___fr",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/fr/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ru",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ru/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___bn",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/bn/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___pt",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/pt/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___km",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/km/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___nl",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/nl/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___pl",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/pl/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___tr",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/tr/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___sv",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/sv/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___cs",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/cs/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___nb",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/nb/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ro",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ro/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___el",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/el/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___da",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/da/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___uk",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/uk/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___hr",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/hr/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___ca",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/ca/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___hu",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/hu/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___sk",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/sk/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___fi",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/fi/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___sl",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/sl/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___bg",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/bg/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I6owR6PKtNMeta?.name ?? "airlines-slug_airline-country-city-slug___sr",
    path: _91slug_93I6owR6PKtNMeta?.path ?? "/sr/airlines/:slug_airline()/country-city/:slug()",
    meta: _91slug_93I6owR6PKtNMeta || {},
    alias: _91slug_93I6owR6PKtNMeta?.alias || [],
    redirect: _91slug_93I6owR6PKtNMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___en",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/en/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___id",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/id/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___tl",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/tl/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___zh",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/zh/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___zh-tw",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/zh-tw/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___th",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/th/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ja",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ja/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ms",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ms/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ko",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ko/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___vi",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/vi/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___it",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/it/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___de",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/de/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___es",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/es/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___hi",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/hi/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___fr",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/fr/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ru",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ru/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___bn",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/bn/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___pt",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/pt/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___km",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/km/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___nl",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/nl/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___pl",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/pl/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___tr",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/tr/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___sv",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/sv/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___cs",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/cs/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___nb",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/nb/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ro",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ro/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___el",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/el/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___da",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/da/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___uk",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/uk/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___hr",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/hr/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___ca",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/ca/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___hu",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/hu/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___sk",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/sk/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___fi",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/fi/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___sl",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/sl/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___bg",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/bg/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yRYkixP4npMeta?.name ?? "airlines-slug_airline-country-country-slug___sr",
    path: _91slug_93yRYkixP4npMeta?.path ?? "/sr/airlines/:slug_airline()/country-country/:slug()",
    meta: _91slug_93yRYkixP4npMeta || {},
    alias: _91slug_93yRYkixP4npMeta?.alias || [],
    redirect: _91slug_93yRYkixP4npMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___en",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/en/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___id",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/id/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___tl",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/tl/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___zh",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/zh/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___zh-tw",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/zh-tw/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___th",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/th/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ja",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ja/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ms",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ms/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ko",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ko/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___vi",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/vi/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___it",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/it/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___de",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/de/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___es",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/es/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___hi",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/hi/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___fr",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/fr/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ru",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ru/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___bn",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/bn/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___pt",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/pt/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___km",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/km/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___nl",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/nl/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___pl",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/pl/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___tr",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/tr/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___sv",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/sv/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___cs",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/cs/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___nb",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/nb/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ro",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ro/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___el",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/el/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___da",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/da/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___uk",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/uk/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___hr",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/hr/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___ca",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/ca/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___hu",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/hu/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___sk",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/sk/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___fi",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/fi/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___sl",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/sl/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___bg",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/bg/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931ki4nWQCaOMeta?.name ?? "airlines-slug_airline-from-airport-slug___sr",
    path: _91slug_931ki4nWQCaOMeta?.path ?? "/sr/airlines/:slug_airline()/from-airport/:slug()",
    meta: _91slug_931ki4nWQCaOMeta || {},
    alias: _91slug_931ki4nWQCaOMeta?.alias || [],
    redirect: _91slug_931ki4nWQCaOMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___en",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/en/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___id",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/id/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___tl",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/tl/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___zh",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/zh/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___zh-tw",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/zh-tw/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___th",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/th/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ja",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ja/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ms",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ms/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ko",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ko/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___vi",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/vi/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___it",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/it/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___de",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/de/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___es",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/es/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___hi",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/hi/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___fr",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/fr/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ru",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ru/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___bn",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/bn/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___pt",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/pt/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___km",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/km/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___nl",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/nl/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___pl",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/pl/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___tr",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/tr/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___sv",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/sv/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___cs",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/cs/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___nb",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/nb/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ro",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ro/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___el",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/el/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___da",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/da/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___uk",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/uk/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___hr",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/hr/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___ca",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/ca/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___hu",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/hu/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___sk",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/sk/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___fi",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/fi/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___sl",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/sl/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___bg",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/bg/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QCT2fZpEQFMeta?.name ?? "airlines-slug_airline-from-city-slug___sr",
    path: _91slug_93QCT2fZpEQFMeta?.path ?? "/sr/airlines/:slug_airline()/from-city/:slug()",
    meta: _91slug_93QCT2fZpEQFMeta || {},
    alias: _91slug_93QCT2fZpEQFMeta?.alias || [],
    redirect: _91slug_93QCT2fZpEQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___en",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/en/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___id",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/id/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___tl",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/tl/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___zh",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/zh/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___zh-tw",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/zh-tw/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___th",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/th/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ja",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ja/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ms",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ms/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ko",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ko/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___vi",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/vi/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___it",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/it/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___de",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/de/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___es",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/es/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___hi",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/hi/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___fr",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/fr/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ru",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ru/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___bn",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/bn/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___pt",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/pt/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___km",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/km/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___nl",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/nl/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___pl",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/pl/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___tr",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/tr/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___sv",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/sv/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___cs",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/cs/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___nb",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/nb/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ro",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ro/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___el",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/el/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___da",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/da/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___uk",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/uk/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___hr",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/hr/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___ca",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/ca/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___hu",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/hu/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___sk",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/sk/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___fi",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/fi/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___sl",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/sl/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___bg",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/bg/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLYeM6G8aXMeta?.name ?? "airlines-slug_airline-from-continent-slug___sr",
    path: _91slug_93HLYeM6G8aXMeta?.path ?? "/sr/airlines/:slug_airline()/from-continent/:slug()",
    meta: _91slug_93HLYeM6G8aXMeta || {},
    alias: _91slug_93HLYeM6G8aXMeta?.alias || [],
    redirect: _91slug_93HLYeM6G8aXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___en",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/en/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___id",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/id/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___tl",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/tl/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___zh",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/zh/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___zh-tw",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/zh-tw/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___th",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/th/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ja",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ja/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ms",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ms/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ko",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ko/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___vi",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/vi/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___it",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/it/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___de",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/de/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___es",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/es/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___hi",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/hi/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___fr",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/fr/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ru",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ru/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___bn",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/bn/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___pt",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/pt/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___km",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/km/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___nl",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/nl/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___pl",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/pl/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___tr",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/tr/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___sv",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/sv/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___cs",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/cs/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___nb",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/nb/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ro",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ro/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___el",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/el/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___da",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/da/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___uk",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/uk/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___hr",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/hr/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___ca",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/ca/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___hu",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/hu/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___sk",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/sk/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___fi",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/fi/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___sl",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/sl/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___bg",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/bg/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938C9fojgsUKMeta?.name ?? "airlines-slug_airline-from-country-slug___sr",
    path: _91slug_938C9fojgsUKMeta?.path ?? "/sr/airlines/:slug_airline()/from-country/:slug()",
    meta: _91slug_938C9fojgsUKMeta || {},
    alias: _91slug_938C9fojgsUKMeta?.alias || [],
    redirect: _91slug_938C9fojgsUKMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/from-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___en",
    path: indexgvA1D7XlOXMeta?.path ?? "/en/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___id",
    path: indexgvA1D7XlOXMeta?.path ?? "/id/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___tl",
    path: indexgvA1D7XlOXMeta?.path ?? "/tl/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___zh",
    path: indexgvA1D7XlOXMeta?.path ?? "/zh/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___zh-tw",
    path: indexgvA1D7XlOXMeta?.path ?? "/zh-tw/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___th",
    path: indexgvA1D7XlOXMeta?.path ?? "/th/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ja",
    path: indexgvA1D7XlOXMeta?.path ?? "/ja/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ms",
    path: indexgvA1D7XlOXMeta?.path ?? "/ms/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ko",
    path: indexgvA1D7XlOXMeta?.path ?? "/ko/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___vi",
    path: indexgvA1D7XlOXMeta?.path ?? "/vi/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___it",
    path: indexgvA1D7XlOXMeta?.path ?? "/it/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___de",
    path: indexgvA1D7XlOXMeta?.path ?? "/de/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___es",
    path: indexgvA1D7XlOXMeta?.path ?? "/es/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___hi",
    path: indexgvA1D7XlOXMeta?.path ?? "/hi/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___fr",
    path: indexgvA1D7XlOXMeta?.path ?? "/fr/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ru",
    path: indexgvA1D7XlOXMeta?.path ?? "/ru/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___bn",
    path: indexgvA1D7XlOXMeta?.path ?? "/bn/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___pt",
    path: indexgvA1D7XlOXMeta?.path ?? "/pt/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___km",
    path: indexgvA1D7XlOXMeta?.path ?? "/km/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___nl",
    path: indexgvA1D7XlOXMeta?.path ?? "/nl/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___pl",
    path: indexgvA1D7XlOXMeta?.path ?? "/pl/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___tr",
    path: indexgvA1D7XlOXMeta?.path ?? "/tr/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___sv",
    path: indexgvA1D7XlOXMeta?.path ?? "/sv/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___cs",
    path: indexgvA1D7XlOXMeta?.path ?? "/cs/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___nb",
    path: indexgvA1D7XlOXMeta?.path ?? "/nb/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ro",
    path: indexgvA1D7XlOXMeta?.path ?? "/ro/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___el",
    path: indexgvA1D7XlOXMeta?.path ?? "/el/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___da",
    path: indexgvA1D7XlOXMeta?.path ?? "/da/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___uk",
    path: indexgvA1D7XlOXMeta?.path ?? "/uk/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___hr",
    path: indexgvA1D7XlOXMeta?.path ?? "/hr/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___ca",
    path: indexgvA1D7XlOXMeta?.path ?? "/ca/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___hu",
    path: indexgvA1D7XlOXMeta?.path ?? "/hu/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___sk",
    path: indexgvA1D7XlOXMeta?.path ?? "/sk/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___fi",
    path: indexgvA1D7XlOXMeta?.path ?? "/fi/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___sl",
    path: indexgvA1D7XlOXMeta?.path ?? "/sl/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___bg",
    path: indexgvA1D7XlOXMeta?.path ?? "/bg/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgvA1D7XlOXMeta?.name ?? "airlines-slug_airline___sr",
    path: indexgvA1D7XlOXMeta?.path ?? "/sr/airlines/:slug_airline()",
    meta: indexgvA1D7XlOXMeta || {},
    alias: indexgvA1D7XlOXMeta?.alias || [],
    redirect: indexgvA1D7XlOXMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___en",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/en/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___id",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/id/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___tl",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/tl/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___zh",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/zh/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___zh-tw",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/zh-tw/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___th",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/th/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ja",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ja/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ms",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ms/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ko",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ko/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___vi",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/vi/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___it",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/it/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___de",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/de/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___es",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/es/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___hi",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/hi/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___fr",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/fr/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ru",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ru/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___bn",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/bn/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___pt",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/pt/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___km",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/km/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___nl",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/nl/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___pl",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/pl/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___tr",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/tr/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___sv",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/sv/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___cs",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/cs/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___nb",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/nb/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ro",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ro/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___el",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/el/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___da",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/da/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___uk",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/uk/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___hr",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/hr/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___ca",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/ca/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___hu",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/hu/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___sk",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/sk/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___fi",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/fi/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___sl",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/sl/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___bg",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/bg/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMEh6bH4STMeta?.name ?? "airlines-slug_airline-to-airport-slug___sr",
    path: _91slug_93KMEh6bH4STMeta?.path ?? "/sr/airlines/:slug_airline()/to-airport/:slug()",
    meta: _91slug_93KMEh6bH4STMeta || {},
    alias: _91slug_93KMEh6bH4STMeta?.alias || [],
    redirect: _91slug_93KMEh6bH4STMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___en",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/en/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___id",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/id/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___tl",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/tl/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___zh",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/zh/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___zh-tw",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/zh-tw/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___th",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/th/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ja",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ja/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ms",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ms/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ko",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ko/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___vi",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/vi/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___it",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/it/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___de",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/de/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___es",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/es/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___hi",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/hi/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___fr",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/fr/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ru",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ru/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___bn",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/bn/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___pt",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/pt/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___km",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/km/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___nl",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/nl/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___pl",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/pl/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___tr",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/tr/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___sv",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/sv/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___cs",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/cs/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___nb",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/nb/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ro",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ro/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___el",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/el/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___da",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/da/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___uk",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/uk/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___hr",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/hr/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___ca",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/ca/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___hu",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/hu/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___sk",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/sk/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___fi",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/fi/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___sl",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/sl/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___bg",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/bg/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kiPAjKPEcjMeta?.name ?? "airlines-slug_airline-to-city-slug___sr",
    path: _91slug_93kiPAjKPEcjMeta?.path ?? "/sr/airlines/:slug_airline()/to-city/:slug()",
    meta: _91slug_93kiPAjKPEcjMeta || {},
    alias: _91slug_93kiPAjKPEcjMeta?.alias || [],
    redirect: _91slug_93kiPAjKPEcjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___en",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/en/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___id",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/id/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___tl",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/tl/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___zh",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/zh/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___zh-tw",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/zh-tw/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___th",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/th/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ja",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ja/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ms",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ms/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ko",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ko/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___vi",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/vi/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___it",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/it/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___de",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/de/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___es",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/es/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___hi",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/hi/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___fr",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/fr/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ru",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ru/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___bn",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/bn/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___pt",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/pt/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___km",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/km/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___nl",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/nl/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___pl",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/pl/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___tr",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/tr/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___sv",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/sv/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___cs",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/cs/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___nb",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/nb/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ro",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ro/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___el",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/el/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___da",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/da/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___uk",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/uk/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___hr",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/hr/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___ca",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/ca/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___hu",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/hu/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___sk",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/sk/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___fi",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/fi/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___sl",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/sl/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___bg",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/bg/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xNYYrRLxLEMeta?.name ?? "airlines-slug_airline-to-continent-slug___sr",
    path: _91slug_93xNYYrRLxLEMeta?.path ?? "/sr/airlines/:slug_airline()/to-continent/:slug()",
    meta: _91slug_93xNYYrRLxLEMeta || {},
    alias: _91slug_93xNYYrRLxLEMeta?.alias || [],
    redirect: _91slug_93xNYYrRLxLEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___en",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/en/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___id",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/id/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___tl",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/tl/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___zh",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/zh/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___zh-tw",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/zh-tw/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___th",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/th/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ja",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ja/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ms",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ms/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ko",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ko/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___vi",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/vi/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___it",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/it/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___de",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/de/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___es",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/es/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___hi",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/hi/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___fr",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/fr/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ru",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ru/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___bn",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/bn/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___pt",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/pt/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___km",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/km/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___nl",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/nl/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___pl",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/pl/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___tr",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/tr/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___sv",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/sv/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___cs",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/cs/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___nb",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/nb/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ro",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ro/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___el",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/el/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___da",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/da/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___uk",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/uk/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___hr",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/hr/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___ca",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/ca/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___hu",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/hu/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___sk",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/sk/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___fi",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/fi/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___sl",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/sl/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___bg",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/bg/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93e9tEwk5C1YMeta?.name ?? "airlines-slug_airline-to-country-slug___sr",
    path: _91slug_93e9tEwk5C1YMeta?.path ?? "/sr/airlines/:slug_airline()/to-country/:slug()",
    meta: _91slug_93e9tEwk5C1YMeta || {},
    alias: _91slug_93e9tEwk5C1YMeta?.alias || [],
    redirect: _91slug_93e9tEwk5C1YMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/[slug_airline]/to-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___en",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/en/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___id",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/id/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___tl",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/tl/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___zh",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/zh/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___zh-tw",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/zh-tw/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___th",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/th/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ja",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ja/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ms",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ms/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ko",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ko/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___vi",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/vi/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___it",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/it/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___de",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/de/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___es",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/es/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___hi",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/hi/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___fr",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/fr/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ru",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ru/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___bn",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/bn/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___pt",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/pt/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___km",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/km/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___nl",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/nl/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___pl",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/pl/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___tr",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/tr/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___sv",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/sv/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___cs",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/cs/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___nb",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/nb/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ro",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ro/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___el",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/el/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___da",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/da/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___uk",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/uk/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___hr",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/hr/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___ca",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/ca/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___hu",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/hu/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___sk",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/sk/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___fi",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/fi/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___sl",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/sl/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___bg",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/bg/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aKgRVohlVHMeta?.name ?? "airlines-group-slug___sr",
    path: _91slug_93aKgRVohlVHMeta?.path ?? "/sr/airlines/group/:slug()",
    meta: _91slug_93aKgRVohlVHMeta || {},
    alias: _91slug_93aKgRVohlVHMeta?.alias || [],
    redirect: _91slug_93aKgRVohlVHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___en",
    path: indexnVVEiZSRAoMeta?.path ?? "/en/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___id",
    path: indexnVVEiZSRAoMeta?.path ?? "/id/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___tl",
    path: indexnVVEiZSRAoMeta?.path ?? "/tl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___zh",
    path: indexnVVEiZSRAoMeta?.path ?? "/zh/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___zh-tw",
    path: indexnVVEiZSRAoMeta?.path ?? "/zh-tw/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___th",
    path: indexnVVEiZSRAoMeta?.path ?? "/th/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ja",
    path: indexnVVEiZSRAoMeta?.path ?? "/ja/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ms",
    path: indexnVVEiZSRAoMeta?.path ?? "/ms/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ko",
    path: indexnVVEiZSRAoMeta?.path ?? "/ko/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___vi",
    path: indexnVVEiZSRAoMeta?.path ?? "/vi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___it",
    path: indexnVVEiZSRAoMeta?.path ?? "/it/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___de",
    path: indexnVVEiZSRAoMeta?.path ?? "/de/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___es",
    path: indexnVVEiZSRAoMeta?.path ?? "/es/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hi",
    path: indexnVVEiZSRAoMeta?.path ?? "/hi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___fr",
    path: indexnVVEiZSRAoMeta?.path ?? "/fr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ru",
    path: indexnVVEiZSRAoMeta?.path ?? "/ru/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___bn",
    path: indexnVVEiZSRAoMeta?.path ?? "/bn/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___pt",
    path: indexnVVEiZSRAoMeta?.path ?? "/pt/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___km",
    path: indexnVVEiZSRAoMeta?.path ?? "/km/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___nl",
    path: indexnVVEiZSRAoMeta?.path ?? "/nl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___pl",
    path: indexnVVEiZSRAoMeta?.path ?? "/pl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___tr",
    path: indexnVVEiZSRAoMeta?.path ?? "/tr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sv",
    path: indexnVVEiZSRAoMeta?.path ?? "/sv/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___cs",
    path: indexnVVEiZSRAoMeta?.path ?? "/cs/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___nb",
    path: indexnVVEiZSRAoMeta?.path ?? "/nb/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ro",
    path: indexnVVEiZSRAoMeta?.path ?? "/ro/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___el",
    path: indexnVVEiZSRAoMeta?.path ?? "/el/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___da",
    path: indexnVVEiZSRAoMeta?.path ?? "/da/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___uk",
    path: indexnVVEiZSRAoMeta?.path ?? "/uk/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hr",
    path: indexnVVEiZSRAoMeta?.path ?? "/hr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___ca",
    path: indexnVVEiZSRAoMeta?.path ?? "/ca/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___hu",
    path: indexnVVEiZSRAoMeta?.path ?? "/hu/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sk",
    path: indexnVVEiZSRAoMeta?.path ?? "/sk/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___fi",
    path: indexnVVEiZSRAoMeta?.path ?? "/fi/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sl",
    path: indexnVVEiZSRAoMeta?.path ?? "/sl/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___bg",
    path: indexnVVEiZSRAoMeta?.path ?? "/bg/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVVEiZSRAoMeta?.name ?? "airlines___sr",
    path: indexnVVEiZSRAoMeta?.path ?? "/sr/airlines",
    meta: indexnVVEiZSRAoMeta || {},
    alias: indexnVVEiZSRAoMeta?.alias || [],
    redirect: indexnVVEiZSRAoMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airlines/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___en",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/en/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___id",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/id/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___tl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/tl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___zh",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/zh/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___zh-tw",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/zh-tw/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___th",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/th/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ja",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ja/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ms",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ms/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ko",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ko/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___vi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/vi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___it",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/it/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___de",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/de/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___es",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/es/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___fr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/fr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ru",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ru/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___bn",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/bn/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___pt",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/pt/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___km",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/km/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___nl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/nl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___pl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/pl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___tr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/tr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sv",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sv/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___cs",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/cs/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___nb",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/nb/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ro",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ro/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___el",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/el/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___da",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/da/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___uk",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/uk/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___ca",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/ca/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___hu",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/hu/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sk",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sk/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___fi",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/fi/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sl",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sl/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___bg",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/bg/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z7qcujcSq0Meta?.name ?? "airports-slug___sr",
    path: _91slug_93z7qcujcSq0Meta?.path ?? "/sr/airports/:slug()",
    meta: _91slug_93z7qcujcSq0Meta || {},
    alias: _91slug_93z7qcujcSq0Meta?.alias || [],
    redirect: _91slug_93z7qcujcSq0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___en",
    path: indexdAr8D3NQEmMeta?.path ?? "/en/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___id",
    path: indexdAr8D3NQEmMeta?.path ?? "/id/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___tl",
    path: indexdAr8D3NQEmMeta?.path ?? "/tl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___zh",
    path: indexdAr8D3NQEmMeta?.path ?? "/zh/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___zh-tw",
    path: indexdAr8D3NQEmMeta?.path ?? "/zh-tw/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___th",
    path: indexdAr8D3NQEmMeta?.path ?? "/th/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ja",
    path: indexdAr8D3NQEmMeta?.path ?? "/ja/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ms",
    path: indexdAr8D3NQEmMeta?.path ?? "/ms/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ko",
    path: indexdAr8D3NQEmMeta?.path ?? "/ko/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___vi",
    path: indexdAr8D3NQEmMeta?.path ?? "/vi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___it",
    path: indexdAr8D3NQEmMeta?.path ?? "/it/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___de",
    path: indexdAr8D3NQEmMeta?.path ?? "/de/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___es",
    path: indexdAr8D3NQEmMeta?.path ?? "/es/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hi",
    path: indexdAr8D3NQEmMeta?.path ?? "/hi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___fr",
    path: indexdAr8D3NQEmMeta?.path ?? "/fr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ru",
    path: indexdAr8D3NQEmMeta?.path ?? "/ru/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___bn",
    path: indexdAr8D3NQEmMeta?.path ?? "/bn/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___pt",
    path: indexdAr8D3NQEmMeta?.path ?? "/pt/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___km",
    path: indexdAr8D3NQEmMeta?.path ?? "/km/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___nl",
    path: indexdAr8D3NQEmMeta?.path ?? "/nl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___pl",
    path: indexdAr8D3NQEmMeta?.path ?? "/pl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___tr",
    path: indexdAr8D3NQEmMeta?.path ?? "/tr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sv",
    path: indexdAr8D3NQEmMeta?.path ?? "/sv/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___cs",
    path: indexdAr8D3NQEmMeta?.path ?? "/cs/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___nb",
    path: indexdAr8D3NQEmMeta?.path ?? "/nb/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ro",
    path: indexdAr8D3NQEmMeta?.path ?? "/ro/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___el",
    path: indexdAr8D3NQEmMeta?.path ?? "/el/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___da",
    path: indexdAr8D3NQEmMeta?.path ?? "/da/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___uk",
    path: indexdAr8D3NQEmMeta?.path ?? "/uk/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hr",
    path: indexdAr8D3NQEmMeta?.path ?? "/hr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___ca",
    path: indexdAr8D3NQEmMeta?.path ?? "/ca/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___hu",
    path: indexdAr8D3NQEmMeta?.path ?? "/hu/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sk",
    path: indexdAr8D3NQEmMeta?.path ?? "/sk/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___fi",
    path: indexdAr8D3NQEmMeta?.path ?? "/fi/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sl",
    path: indexdAr8D3NQEmMeta?.path ?? "/sl/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___bg",
    path: indexdAr8D3NQEmMeta?.path ?? "/bg/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAr8D3NQEmMeta?.name ?? "airports___sr",
    path: indexdAr8D3NQEmMeta?.path ?? "/sr/airports",
    meta: indexdAr8D3NQEmMeta || {},
    alias: indexdAr8D3NQEmMeta?.alias || [],
    redirect: indexdAr8D3NQEmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/airports/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___en",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/en/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___id",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/id/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___tl",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/tl/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___zh",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/zh/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___zh-tw",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/zh-tw/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___th",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/th/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ja",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ja/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ms",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ms/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ko",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ko/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___vi",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/vi/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___it",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/it/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___de",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/de/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___es",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/es/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___hi",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/hi/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___fr",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/fr/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ru",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ru/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___bn",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/bn/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___pt",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/pt/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___km",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/km/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___nl",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/nl/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___pl",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/pl/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___tr",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/tr/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___sv",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/sv/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___cs",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/cs/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___nb",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/nb/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ro",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ro/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___el",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/el/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___da",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/da/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___uk",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/uk/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___hr",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/hr/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___ca",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/ca/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___hu",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/hu/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___sk",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/sk/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___fi",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/fi/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___sl",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/sl/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___bg",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/bg/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqUDHMUQ9XMeta?.name ?? "flight-from-airport-slug___sr",
    path: _91slug_93rqUDHMUQ9XMeta?.path ?? "/sr/flight-from/airport/:slug()",
    meta: _91slug_93rqUDHMUQ9XMeta || {},
    alias: _91slug_93rqUDHMUQ9XMeta?.alias || [],
    redirect: _91slug_93rqUDHMUQ9XMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___en",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/en/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___id",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/id/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___tl",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/tl/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___zh",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/zh/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___zh-tw",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/zh-tw/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___th",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/th/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ja",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ja/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ms",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ms/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ko",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ko/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___vi",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/vi/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___it",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/it/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___de",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/de/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___es",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/es/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___hi",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/hi/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___fr",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/fr/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ru",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ru/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___bn",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/bn/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___pt",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/pt/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___km",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/km/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___nl",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/nl/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___pl",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/pl/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___tr",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/tr/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___sv",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/sv/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___cs",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/cs/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___nb",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/nb/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ro",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ro/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___el",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/el/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___da",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/da/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___uk",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/uk/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___hr",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/hr/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___ca",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/ca/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___hu",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/hu/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___sk",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/sk/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___fi",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/fi/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___sl",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/sl/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___bg",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/bg/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939DgOHOzkTuMeta?.name ?? "flight-from-city-slug___sr",
    path: _91slug_939DgOHOzkTuMeta?.path ?? "/sr/flight-from/city/:slug()",
    meta: _91slug_939DgOHOzkTuMeta || {},
    alias: _91slug_939DgOHOzkTuMeta?.alias || [],
    redirect: _91slug_939DgOHOzkTuMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___en",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/en/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___id",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/id/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___tl",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/tl/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___zh",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/zh/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___zh-tw",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/zh-tw/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___th",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/th/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ja",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ja/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ms",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ms/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ko",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ko/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___vi",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/vi/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___it",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/it/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___de",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/de/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___es",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/es/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___hi",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/hi/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___fr",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/fr/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ru",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ru/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___bn",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/bn/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___pt",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/pt/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___km",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/km/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___nl",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/nl/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___pl",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/pl/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___tr",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/tr/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___sv",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/sv/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___cs",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/cs/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___nb",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/nb/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ro",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ro/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___el",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/el/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___da",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/da/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___uk",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/uk/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___hr",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/hr/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___ca",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/ca/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___hu",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/hu/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___sk",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/sk/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___fi",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/fi/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___sl",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/sl/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___bg",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/bg/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wzcuBDb5xDMeta?.name ?? "flight-from-continent-slug___sr",
    path: _91slug_93wzcuBDb5xDMeta?.path ?? "/sr/flight-from/continent/:slug()",
    meta: _91slug_93wzcuBDb5xDMeta || {},
    alias: _91slug_93wzcuBDb5xDMeta?.alias || [],
    redirect: _91slug_93wzcuBDb5xDMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___en",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/en/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___id",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/id/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___tl",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/tl/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___zh",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/zh/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___zh-tw",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/zh-tw/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___th",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/th/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ja",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ja/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ms",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ms/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ko",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ko/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___vi",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/vi/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___it",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/it/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___de",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/de/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___es",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/es/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___hi",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/hi/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___fr",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/fr/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ru",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ru/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___bn",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/bn/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___pt",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/pt/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___km",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/km/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___nl",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/nl/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___pl",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/pl/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___tr",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/tr/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___sv",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/sv/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___cs",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/cs/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___nb",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/nb/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ro",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ro/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___el",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/el/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___da",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/da/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___uk",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/uk/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___hr",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/hr/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___ca",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/ca/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___hu",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/hu/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___sk",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/sk/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___fi",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/fi/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___sl",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/sl/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___bg",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/bg/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZE2P7gqYzTMeta?.name ?? "flight-from-country-slug___sr",
    path: _91slug_93ZE2P7gqYzTMeta?.path ?? "/sr/flight-from/country/:slug()",
    meta: _91slug_93ZE2P7gqYzTMeta || {},
    alias: _91slug_93ZE2P7gqYzTMeta?.alias || [],
    redirect: _91slug_93ZE2P7gqYzTMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___en",
    path: index84hEBuWar7Meta?.path ?? "/en/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___id",
    path: index84hEBuWar7Meta?.path ?? "/id/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___tl",
    path: index84hEBuWar7Meta?.path ?? "/tl/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___zh",
    path: index84hEBuWar7Meta?.path ?? "/zh/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___zh-tw",
    path: index84hEBuWar7Meta?.path ?? "/zh-tw/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___th",
    path: index84hEBuWar7Meta?.path ?? "/th/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ja",
    path: index84hEBuWar7Meta?.path ?? "/ja/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ms",
    path: index84hEBuWar7Meta?.path ?? "/ms/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ko",
    path: index84hEBuWar7Meta?.path ?? "/ko/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___vi",
    path: index84hEBuWar7Meta?.path ?? "/vi/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___it",
    path: index84hEBuWar7Meta?.path ?? "/it/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___de",
    path: index84hEBuWar7Meta?.path ?? "/de/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___es",
    path: index84hEBuWar7Meta?.path ?? "/es/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___hi",
    path: index84hEBuWar7Meta?.path ?? "/hi/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___fr",
    path: index84hEBuWar7Meta?.path ?? "/fr/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ru",
    path: index84hEBuWar7Meta?.path ?? "/ru/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___bn",
    path: index84hEBuWar7Meta?.path ?? "/bn/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___pt",
    path: index84hEBuWar7Meta?.path ?? "/pt/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___km",
    path: index84hEBuWar7Meta?.path ?? "/km/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___nl",
    path: index84hEBuWar7Meta?.path ?? "/nl/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___pl",
    path: index84hEBuWar7Meta?.path ?? "/pl/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___tr",
    path: index84hEBuWar7Meta?.path ?? "/tr/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___sv",
    path: index84hEBuWar7Meta?.path ?? "/sv/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___cs",
    path: index84hEBuWar7Meta?.path ?? "/cs/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___nb",
    path: index84hEBuWar7Meta?.path ?? "/nb/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ro",
    path: index84hEBuWar7Meta?.path ?? "/ro/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___el",
    path: index84hEBuWar7Meta?.path ?? "/el/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___da",
    path: index84hEBuWar7Meta?.path ?? "/da/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___uk",
    path: index84hEBuWar7Meta?.path ?? "/uk/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___hr",
    path: index84hEBuWar7Meta?.path ?? "/hr/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___ca",
    path: index84hEBuWar7Meta?.path ?? "/ca/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___hu",
    path: index84hEBuWar7Meta?.path ?? "/hu/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___sk",
    path: index84hEBuWar7Meta?.path ?? "/sk/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___fi",
    path: index84hEBuWar7Meta?.path ?? "/fi/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___sl",
    path: index84hEBuWar7Meta?.path ?? "/sl/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___bg",
    path: index84hEBuWar7Meta?.path ?? "/bg/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: index84hEBuWar7Meta?.name ?? "flight-from___sr",
    path: index84hEBuWar7Meta?.path ?? "/sr/flight-from",
    meta: index84hEBuWar7Meta || {},
    alias: index84hEBuWar7Meta?.alias || [],
    redirect: index84hEBuWar7Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-from/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___en",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/en/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___id",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/id/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___tl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/tl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___zh",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/zh/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___zh-tw",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/zh-tw/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___th",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/th/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ja",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ja/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ms",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ms/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ko",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ko/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___vi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/vi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___it",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/it/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___de",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/de/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___es",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/es/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___fr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/fr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ru",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ru/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___bn",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/bn/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___pt",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/pt/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___km",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/km/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___nl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/nl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___pl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/pl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___tr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/tr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sv",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sv/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___cs",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/cs/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___nb",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/nb/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ro",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ro/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___el",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/el/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___da",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/da/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___uk",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/uk/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___ca",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/ca/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___hu",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/hu/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sk",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sk/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___fi",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/fi/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sl",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sl/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___bg",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/bg/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Ukxj2XB8ouMeta?.name ?? "flight-tickets-slug___sr",
    path: _91slug_93Ukxj2XB8ouMeta?.path ?? "/sr/flight-tickets/:slug()",
    meta: _91slug_93Ukxj2XB8ouMeta || {},
    alias: _91slug_93Ukxj2XB8ouMeta?.alias || [],
    redirect: _91slug_93Ukxj2XB8ouMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___en",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/en/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___id",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/id/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___tl",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/tl/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___zh",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/zh/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___zh-tw",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/zh-tw/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___th",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/th/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ja",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ja/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ms",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ms/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ko",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ko/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___vi",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/vi/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___it",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/it/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___de",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/de/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___es",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/es/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___hi",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/hi/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___fr",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/fr/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ru",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ru/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___bn",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/bn/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___pt",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/pt/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___km",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/km/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___nl",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/nl/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___pl",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/pl/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___tr",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/tr/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___sv",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/sv/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___cs",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/cs/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___nb",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/nb/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ro",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ro/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___el",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/el/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___da",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/da/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___uk",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/uk/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___hr",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/hr/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___ca",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/ca/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___hu",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/hu/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___sk",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/sk/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___fi",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/fi/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___sl",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/sl/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___bg",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/bg/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nwc3jeXoF5Meta?.name ?? "flight-tickets-airport-airport-slug___sr",
    path: _91slug_93nwc3jeXoF5Meta?.path ?? "/sr/flight-tickets/airport-airport/:slug()",
    meta: _91slug_93nwc3jeXoF5Meta || {},
    alias: _91slug_93nwc3jeXoF5Meta?.alias || [],
    redirect: _91slug_93nwc3jeXoF5Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___en",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/en/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___id",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/id/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___tl",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/tl/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___zh",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/zh/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___zh-tw",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/zh-tw/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___th",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/th/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ja",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ja/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ms",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ms/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ko",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ko/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___vi",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/vi/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___it",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/it/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___de",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/de/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___es",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/es/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___hi",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/hi/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___fr",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/fr/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ru",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ru/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___bn",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/bn/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___pt",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/pt/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___km",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/km/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___nl",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/nl/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___pl",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/pl/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___tr",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/tr/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___sv",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/sv/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___cs",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/cs/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___nb",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/nb/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ro",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ro/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___el",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/el/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___da",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/da/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___uk",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/uk/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___hr",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/hr/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___ca",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/ca/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___hu",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/hu/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___sk",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/sk/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___fi",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/fi/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___sl",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/sl/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___bg",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/bg/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLzJ1UrZEEMeta?.name ?? "flight-tickets-airport-city-slug___sr",
    path: _91slug_93eLzJ1UrZEEMeta?.path ?? "/sr/flight-tickets/airport-city/:slug()",
    meta: _91slug_93eLzJ1UrZEEMeta || {},
    alias: _91slug_93eLzJ1UrZEEMeta?.alias || [],
    redirect: _91slug_93eLzJ1UrZEEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___en",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/en/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___id",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/id/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___tl",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/tl/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___zh",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/zh/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___zh-tw",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/zh-tw/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___th",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/th/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ja",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ja/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ms",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ms/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ko",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ko/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___vi",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/vi/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___it",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/it/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___de",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/de/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___es",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/es/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___hi",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/hi/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___fr",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/fr/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ru",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ru/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___bn",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/bn/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___pt",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/pt/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___km",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/km/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___nl",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/nl/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___pl",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/pl/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___tr",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/tr/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___sv",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/sv/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___cs",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/cs/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___nb",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/nb/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ro",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ro/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___el",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/el/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___da",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/da/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___uk",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/uk/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___hr",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/hr/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___ca",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/ca/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___hu",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/hu/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___sk",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/sk/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___fi",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/fi/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___sl",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/sl/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___bg",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/bg/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k9pqAuqaBHMeta?.name ?? "flight-tickets-airport-country-slug___sr",
    path: _91slug_93k9pqAuqaBHMeta?.path ?? "/sr/flight-tickets/airport-country/:slug()",
    meta: _91slug_93k9pqAuqaBHMeta || {},
    alias: _91slug_93k9pqAuqaBHMeta?.alias || [],
    redirect: _91slug_93k9pqAuqaBHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/airport-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___en",
    path: _91slug_939VMILdIglmMeta?.path ?? "/en/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___id",
    path: _91slug_939VMILdIglmMeta?.path ?? "/id/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___tl",
    path: _91slug_939VMILdIglmMeta?.path ?? "/tl/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___zh",
    path: _91slug_939VMILdIglmMeta?.path ?? "/zh/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___zh-tw",
    path: _91slug_939VMILdIglmMeta?.path ?? "/zh-tw/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___th",
    path: _91slug_939VMILdIglmMeta?.path ?? "/th/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ja",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ja/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ms",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ms/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ko",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ko/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___vi",
    path: _91slug_939VMILdIglmMeta?.path ?? "/vi/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___it",
    path: _91slug_939VMILdIglmMeta?.path ?? "/it/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___de",
    path: _91slug_939VMILdIglmMeta?.path ?? "/de/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___es",
    path: _91slug_939VMILdIglmMeta?.path ?? "/es/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___hi",
    path: _91slug_939VMILdIglmMeta?.path ?? "/hi/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___fr",
    path: _91slug_939VMILdIglmMeta?.path ?? "/fr/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ru",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ru/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___bn",
    path: _91slug_939VMILdIglmMeta?.path ?? "/bn/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___pt",
    path: _91slug_939VMILdIglmMeta?.path ?? "/pt/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___km",
    path: _91slug_939VMILdIglmMeta?.path ?? "/km/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___nl",
    path: _91slug_939VMILdIglmMeta?.path ?? "/nl/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___pl",
    path: _91slug_939VMILdIglmMeta?.path ?? "/pl/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___tr",
    path: _91slug_939VMILdIglmMeta?.path ?? "/tr/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___sv",
    path: _91slug_939VMILdIglmMeta?.path ?? "/sv/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___cs",
    path: _91slug_939VMILdIglmMeta?.path ?? "/cs/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___nb",
    path: _91slug_939VMILdIglmMeta?.path ?? "/nb/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ro",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ro/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___el",
    path: _91slug_939VMILdIglmMeta?.path ?? "/el/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___da",
    path: _91slug_939VMILdIglmMeta?.path ?? "/da/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___uk",
    path: _91slug_939VMILdIglmMeta?.path ?? "/uk/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___hr",
    path: _91slug_939VMILdIglmMeta?.path ?? "/hr/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___ca",
    path: _91slug_939VMILdIglmMeta?.path ?? "/ca/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___hu",
    path: _91slug_939VMILdIglmMeta?.path ?? "/hu/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___sk",
    path: _91slug_939VMILdIglmMeta?.path ?? "/sk/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___fi",
    path: _91slug_939VMILdIglmMeta?.path ?? "/fi/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___sl",
    path: _91slug_939VMILdIglmMeta?.path ?? "/sl/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___bg",
    path: _91slug_939VMILdIglmMeta?.path ?? "/bg/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939VMILdIglmMeta?.name ?? "flight-tickets-city-airport-slug___sr",
    path: _91slug_939VMILdIglmMeta?.path ?? "/sr/flight-tickets/city-airport/:slug()",
    meta: _91slug_939VMILdIglmMeta || {},
    alias: _91slug_939VMILdIglmMeta?.alias || [],
    redirect: _91slug_939VMILdIglmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___en",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/en/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___id",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/id/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___tl",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/tl/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___zh",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/zh/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___zh-tw",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/zh-tw/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___th",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/th/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ja",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ja/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ms",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ms/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ko",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ko/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___vi",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/vi/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___it",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/it/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___de",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/de/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___es",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/es/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___hi",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/hi/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___fr",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/fr/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ru",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ru/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___bn",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/bn/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___pt",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/pt/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___km",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/km/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___nl",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/nl/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___pl",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/pl/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___tr",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/tr/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___sv",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/sv/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___cs",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/cs/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___nb",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/nb/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ro",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ro/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___el",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/el/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___da",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/da/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___uk",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/uk/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___hr",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/hr/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___ca",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/ca/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___hu",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/hu/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___sk",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/sk/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___fi",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/fi/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___sl",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/sl/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___bg",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/bg/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dnN11SISnmMeta?.name ?? "flight-tickets-city-city-slug___sr",
    path: _91slug_93dnN11SISnmMeta?.path ?? "/sr/flight-tickets/city-city/:slug()",
    meta: _91slug_93dnN11SISnmMeta || {},
    alias: _91slug_93dnN11SISnmMeta?.alias || [],
    redirect: _91slug_93dnN11SISnmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___en",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/en/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___id",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/id/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___tl",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/tl/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___zh",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/zh/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___zh-tw",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/zh-tw/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___th",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/th/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ja",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ja/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ms",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ms/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ko",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ko/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___vi",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/vi/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___it",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/it/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___de",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/de/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___es",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/es/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___hi",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/hi/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___fr",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/fr/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ru",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ru/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___bn",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/bn/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___pt",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/pt/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___km",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/km/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___nl",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/nl/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___pl",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/pl/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___tr",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/tr/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___sv",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/sv/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___cs",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/cs/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___nb",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/nb/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ro",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ro/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___el",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/el/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___da",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/da/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___uk",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/uk/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___hr",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/hr/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___ca",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/ca/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___hu",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/hu/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___sk",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/sk/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___fi",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/fi/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___sl",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/sl/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___bg",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/bg/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OCUFwik12uMeta?.name ?? "flight-tickets-city-country-slug___sr",
    path: _91slug_93OCUFwik12uMeta?.path ?? "/sr/flight-tickets/city-country/:slug()",
    meta: _91slug_93OCUFwik12uMeta || {},
    alias: _91slug_93OCUFwik12uMeta?.alias || [],
    redirect: _91slug_93OCUFwik12uMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/city-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___en",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/en/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___id",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/id/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___tl",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/tl/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___zh",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/zh/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___zh-tw",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/zh-tw/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___th",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/th/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ja",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ja/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ms",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ms/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ko",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ko/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___vi",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/vi/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___it",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/it/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___de",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/de/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___es",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/es/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___hi",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/hi/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___fr",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/fr/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ru",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ru/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___bn",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/bn/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___pt",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/pt/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___km",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/km/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___nl",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/nl/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___pl",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/pl/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___tr",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/tr/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___sv",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/sv/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___cs",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/cs/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___nb",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/nb/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ro",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ro/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___el",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/el/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___da",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/da/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___uk",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/uk/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___hr",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/hr/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___ca",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/ca/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___hu",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/hu/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___sk",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/sk/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___fi",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/fi/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___sl",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/sl/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___bg",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/bg/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f1pHAaoAVJMeta?.name ?? "flight-tickets-continent-continent-slug___sr",
    path: _91slug_93f1pHAaoAVJMeta?.path ?? "/sr/flight-tickets/continent-continent/:slug()",
    meta: _91slug_93f1pHAaoAVJMeta || {},
    alias: _91slug_93f1pHAaoAVJMeta?.alias || [],
    redirect: _91slug_93f1pHAaoAVJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/continent-continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___en",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/en/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___id",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/id/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___tl",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/tl/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___zh",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/zh/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___zh-tw",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/zh-tw/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___th",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/th/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ja",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ja/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ms",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ms/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ko",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ko/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___vi",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/vi/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___it",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/it/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___de",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/de/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___es",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/es/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___hi",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/hi/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___fr",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/fr/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ru",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ru/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___bn",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/bn/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___pt",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/pt/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___km",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/km/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___nl",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/nl/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___pl",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/pl/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___tr",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/tr/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___sv",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/sv/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___cs",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/cs/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___nb",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/nb/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ro",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ro/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___el",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/el/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___da",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/da/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___uk",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/uk/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___hr",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/hr/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___ca",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/ca/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___hu",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/hu/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___sk",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/sk/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___fi",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/fi/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___sl",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/sl/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___bg",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/bg/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NOTbVW6CBjMeta?.name ?? "flight-tickets-country-airport-slug___sr",
    path: _91slug_93NOTbVW6CBjMeta?.path ?? "/sr/flight-tickets/country-airport/:slug()",
    meta: _91slug_93NOTbVW6CBjMeta || {},
    alias: _91slug_93NOTbVW6CBjMeta?.alias || [],
    redirect: _91slug_93NOTbVW6CBjMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___en",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/en/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___id",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/id/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___tl",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/tl/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___zh",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/zh/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___zh-tw",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/zh-tw/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___th",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/th/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ja",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ja/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ms",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ms/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ko",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ko/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___vi",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/vi/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___it",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/it/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___de",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/de/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___es",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/es/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___hi",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/hi/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___fr",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/fr/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ru",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ru/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___bn",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/bn/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___pt",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/pt/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___km",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/km/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___nl",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/nl/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___pl",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/pl/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___tr",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/tr/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___sv",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/sv/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___cs",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/cs/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___nb",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/nb/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ro",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ro/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___el",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/el/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___da",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/da/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___uk",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/uk/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___hr",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/hr/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___ca",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/ca/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___hu",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/hu/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___sk",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/sk/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___fi",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/fi/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___sl",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/sl/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___bg",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/bg/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mh1wwNDrnbMeta?.name ?? "flight-tickets-country-city-slug___sr",
    path: _91slug_93Mh1wwNDrnbMeta?.path ?? "/sr/flight-tickets/country-city/:slug()",
    meta: _91slug_93Mh1wwNDrnbMeta || {},
    alias: _91slug_93Mh1wwNDrnbMeta?.alias || [],
    redirect: _91slug_93Mh1wwNDrnbMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___en",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/en/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___id",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/id/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___tl",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/tl/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___zh",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/zh/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___zh-tw",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/zh-tw/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___th",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/th/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ja",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ja/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ms",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ms/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ko",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ko/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___vi",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/vi/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___it",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/it/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___de",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/de/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___es",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/es/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___hi",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/hi/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___fr",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/fr/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ru",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ru/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___bn",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/bn/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___pt",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/pt/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___km",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/km/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___nl",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/nl/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___pl",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/pl/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___tr",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/tr/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___sv",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/sv/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___cs",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/cs/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___nb",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/nb/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ro",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ro/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___el",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/el/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___da",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/da/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___uk",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/uk/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___hr",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/hr/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___ca",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/ca/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___hu",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/hu/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___sk",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/sk/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___fi",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/fi/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___sl",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/sl/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___bg",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/bg/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wSSz3oeHbZMeta?.name ?? "flight-tickets-country-country-slug___sr",
    path: _91slug_93wSSz3oeHbZMeta?.path ?? "/sr/flight-tickets/country-country/:slug()",
    meta: _91slug_93wSSz3oeHbZMeta || {},
    alias: _91slug_93wSSz3oeHbZMeta?.alias || [],
    redirect: _91slug_93wSSz3oeHbZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-tickets/country-country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___en",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/en/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___id",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/id/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___tl",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/tl/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___zh",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/zh/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___zh-tw",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/zh-tw/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___th",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/th/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ja",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ja/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ms",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ms/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ko",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ko/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___vi",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/vi/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___it",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/it/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___de",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/de/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___es",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/es/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___hi",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/hi/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___fr",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/fr/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ru",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ru/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___bn",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/bn/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___pt",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/pt/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___km",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/km/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___nl",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/nl/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___pl",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/pl/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___tr",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/tr/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___sv",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/sv/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___cs",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/cs/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___nb",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/nb/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ro",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ro/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___el",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/el/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___da",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/da/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___uk",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/uk/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___hr",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/hr/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___ca",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/ca/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___hu",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/hu/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___sk",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/sk/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___fi",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/fi/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___sl",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/sl/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___bg",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/bg/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ArLowJD3sPMeta?.name ?? "flight-to-airport-slug___sr",
    path: _91slug_93ArLowJD3sPMeta?.path ?? "/sr/flight-to/airport/:slug()",
    meta: _91slug_93ArLowJD3sPMeta || {},
    alias: _91slug_93ArLowJD3sPMeta?.alias || [],
    redirect: _91slug_93ArLowJD3sPMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___en",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/en/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___id",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/id/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___tl",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/tl/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___zh",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/zh/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___zh-tw",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/zh-tw/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___th",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/th/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ja",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ja/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ms",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ms/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ko",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ko/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___vi",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/vi/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___it",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/it/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___de",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/de/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___es",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/es/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___hi",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/hi/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___fr",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/fr/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ru",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ru/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___bn",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/bn/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___pt",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/pt/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___km",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/km/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___nl",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/nl/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___pl",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/pl/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___tr",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/tr/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___sv",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/sv/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___cs",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/cs/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___nb",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/nb/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ro",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ro/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___el",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/el/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___da",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/da/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___uk",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/uk/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___hr",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/hr/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___ca",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/ca/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___hu",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/hu/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___sk",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/sk/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___fi",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/fi/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___sl",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/sl/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___bg",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/bg/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YnNndc1mmUMeta?.name ?? "flight-to-city-slug___sr",
    path: _91slug_93YnNndc1mmUMeta?.path ?? "/sr/flight-to/city/:slug()",
    meta: _91slug_93YnNndc1mmUMeta || {},
    alias: _91slug_93YnNndc1mmUMeta?.alias || [],
    redirect: _91slug_93YnNndc1mmUMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/city/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___en",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/en/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___id",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/id/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___tl",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/tl/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___zh",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/zh/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___zh-tw",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/zh-tw/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___th",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/th/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ja",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ja/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ms",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ms/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ko",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ko/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___vi",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/vi/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___it",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/it/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___de",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/de/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___es",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/es/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___hi",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/hi/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___fr",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/fr/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ru",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ru/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___bn",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/bn/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___pt",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/pt/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___km",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/km/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___nl",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/nl/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___pl",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/pl/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___tr",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/tr/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___sv",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/sv/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___cs",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/cs/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___nb",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/nb/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ro",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ro/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___el",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/el/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___da",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/da/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___uk",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/uk/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___hr",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/hr/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___ca",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/ca/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___hu",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/hu/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___sk",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/sk/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___fi",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/fi/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___sl",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/sl/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___bg",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/bg/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIV7Bm18RAMeta?.name ?? "flight-to-continent-slug___sr",
    path: _91slug_93fIV7Bm18RAMeta?.path ?? "/sr/flight-to/continent/:slug()",
    meta: _91slug_93fIV7Bm18RAMeta || {},
    alias: _91slug_93fIV7Bm18RAMeta?.alias || [],
    redirect: _91slug_93fIV7Bm18RAMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/continent/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___en",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/en/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___id",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/id/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___tl",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/tl/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___zh",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/zh/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___zh-tw",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/zh-tw/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___th",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/th/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ja",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ja/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ms",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ms/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ko",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ko/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___vi",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/vi/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___it",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/it/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___de",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/de/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___es",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/es/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___hi",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/hi/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___fr",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/fr/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ru",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ru/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___bn",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/bn/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___pt",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/pt/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___km",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/km/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___nl",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/nl/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___pl",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/pl/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___tr",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/tr/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___sv",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/sv/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___cs",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/cs/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___nb",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/nb/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ro",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ro/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___el",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/el/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___da",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/da/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___uk",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/uk/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___hr",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/hr/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___ca",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/ca/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___hu",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/hu/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___sk",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/sk/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___fi",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/fi/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___sl",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/sl/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___bg",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/bg/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wUh2thKaIlMeta?.name ?? "flight-to-country-slug___sr",
    path: _91slug_93wUh2thKaIlMeta?.path ?? "/sr/flight-to/country/:slug()",
    meta: _91slug_93wUh2thKaIlMeta || {},
    alias: _91slug_93wUh2thKaIlMeta?.alias || [],
    redirect: _91slug_93wUh2thKaIlMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/country/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___en",
    path: indexeGW3ihEjAhMeta?.path ?? "/en/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___id",
    path: indexeGW3ihEjAhMeta?.path ?? "/id/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___tl",
    path: indexeGW3ihEjAhMeta?.path ?? "/tl/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___zh",
    path: indexeGW3ihEjAhMeta?.path ?? "/zh/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___zh-tw",
    path: indexeGW3ihEjAhMeta?.path ?? "/zh-tw/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___th",
    path: indexeGW3ihEjAhMeta?.path ?? "/th/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ja",
    path: indexeGW3ihEjAhMeta?.path ?? "/ja/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ms",
    path: indexeGW3ihEjAhMeta?.path ?? "/ms/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ko",
    path: indexeGW3ihEjAhMeta?.path ?? "/ko/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___vi",
    path: indexeGW3ihEjAhMeta?.path ?? "/vi/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___it",
    path: indexeGW3ihEjAhMeta?.path ?? "/it/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___de",
    path: indexeGW3ihEjAhMeta?.path ?? "/de/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___es",
    path: indexeGW3ihEjAhMeta?.path ?? "/es/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___hi",
    path: indexeGW3ihEjAhMeta?.path ?? "/hi/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___fr",
    path: indexeGW3ihEjAhMeta?.path ?? "/fr/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ru",
    path: indexeGW3ihEjAhMeta?.path ?? "/ru/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___bn",
    path: indexeGW3ihEjAhMeta?.path ?? "/bn/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___pt",
    path: indexeGW3ihEjAhMeta?.path ?? "/pt/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___km",
    path: indexeGW3ihEjAhMeta?.path ?? "/km/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___nl",
    path: indexeGW3ihEjAhMeta?.path ?? "/nl/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___pl",
    path: indexeGW3ihEjAhMeta?.path ?? "/pl/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___tr",
    path: indexeGW3ihEjAhMeta?.path ?? "/tr/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___sv",
    path: indexeGW3ihEjAhMeta?.path ?? "/sv/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___cs",
    path: indexeGW3ihEjAhMeta?.path ?? "/cs/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___nb",
    path: indexeGW3ihEjAhMeta?.path ?? "/nb/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ro",
    path: indexeGW3ihEjAhMeta?.path ?? "/ro/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___el",
    path: indexeGW3ihEjAhMeta?.path ?? "/el/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___da",
    path: indexeGW3ihEjAhMeta?.path ?? "/da/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___uk",
    path: indexeGW3ihEjAhMeta?.path ?? "/uk/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___hr",
    path: indexeGW3ihEjAhMeta?.path ?? "/hr/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___ca",
    path: indexeGW3ihEjAhMeta?.path ?? "/ca/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___hu",
    path: indexeGW3ihEjAhMeta?.path ?? "/hu/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___sk",
    path: indexeGW3ihEjAhMeta?.path ?? "/sk/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___fi",
    path: indexeGW3ihEjAhMeta?.path ?? "/fi/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___sl",
    path: indexeGW3ihEjAhMeta?.path ?? "/sl/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___bg",
    path: indexeGW3ihEjAhMeta?.path ?? "/bg/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: indexeGW3ihEjAhMeta?.name ?? "flight-to___sr",
    path: indexeGW3ihEjAhMeta?.path ?? "/sr/flight-to",
    meta: indexeGW3ihEjAhMeta || {},
    alias: indexeGW3ihEjAhMeta?.alias || [],
    redirect: indexeGW3ihEjAhMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight-to/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___en",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/en/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___id",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/id/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___tl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/tl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___zh",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/zh/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___zh-tw",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/zh-tw/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___th",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/th/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ja",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ja/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ms",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ms/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ko",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ko/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___vi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/vi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___it",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/it/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___de",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/de/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___es",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/es/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___fr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/fr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ru",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ru/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___bn",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/bn/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___pt",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/pt/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___km",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/km/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___nl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/nl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___pl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/pl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___tr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/tr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sv",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sv/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___cs",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/cs/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___nb",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/nb/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ro",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ro/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___el",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/el/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___da",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/da/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___uk",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/uk/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___ca",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/ca/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___hu",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/hu/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sk",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sk/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___fi",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/fi/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sl",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sl/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___bg",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/bg/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HgQsW7PPxEMeta?.name ?? "flight-code-slug___sr",
    path: _91slug_93HgQsW7PPxEMeta?.path ?? "/sr/flight/code/:slug()",
    meta: _91slug_93HgQsW7PPxEMeta || {},
    alias: _91slug_93HgQsW7PPxEMeta?.alias || [],
    redirect: _91slug_93HgQsW7PPxEMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/code/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___en",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/en/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___id",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/id/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___tl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/tl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___zh",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/zh/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___zh-tw",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/zh-tw/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___th",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/th/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ja",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ja/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ms",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ms/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ko",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ko/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___vi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/vi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___it",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/it/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___de",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/de/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___es",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/es/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___fr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/fr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ru",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ru/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___bn",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/bn/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___pt",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/pt/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___km",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/km/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___nl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/nl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___pl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/pl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___tr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/tr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sv",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sv/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___cs",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/cs/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___nb",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/nb/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ro",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ro/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___el",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/el/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___da",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/da/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___uk",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/uk/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___ca",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/ca/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___hu",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/hu/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sk",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sk/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___fi",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/fi/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sl",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sl/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___bg",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/bg/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93f59XN2OWKtMeta?.name ?? "flight-to-slug___sr",
    path: _91slug_93f59XN2OWKtMeta?.path ?? "/sr/flight/to/:slug()",
    meta: _91slug_93f59XN2OWKtMeta || {},
    alias: _91slug_93f59XN2OWKtMeta?.alias || [],
    redirect: _91slug_93f59XN2OWKtMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___en",
    path: indexvLIquZIOLmMeta?.path ?? "/en/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___id",
    path: indexvLIquZIOLmMeta?.path ?? "/id/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___tl",
    path: indexvLIquZIOLmMeta?.path ?? "/tl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___zh",
    path: indexvLIquZIOLmMeta?.path ?? "/zh/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___zh-tw",
    path: indexvLIquZIOLmMeta?.path ?? "/zh-tw/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___th",
    path: indexvLIquZIOLmMeta?.path ?? "/th/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ja",
    path: indexvLIquZIOLmMeta?.path ?? "/ja/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ms",
    path: indexvLIquZIOLmMeta?.path ?? "/ms/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ko",
    path: indexvLIquZIOLmMeta?.path ?? "/ko/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___vi",
    path: indexvLIquZIOLmMeta?.path ?? "/vi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___it",
    path: indexvLIquZIOLmMeta?.path ?? "/it/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___de",
    path: indexvLIquZIOLmMeta?.path ?? "/de/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___es",
    path: indexvLIquZIOLmMeta?.path ?? "/es/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hi",
    path: indexvLIquZIOLmMeta?.path ?? "/hi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___fr",
    path: indexvLIquZIOLmMeta?.path ?? "/fr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ru",
    path: indexvLIquZIOLmMeta?.path ?? "/ru/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___bn",
    path: indexvLIquZIOLmMeta?.path ?? "/bn/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___pt",
    path: indexvLIquZIOLmMeta?.path ?? "/pt/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___km",
    path: indexvLIquZIOLmMeta?.path ?? "/km/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___nl",
    path: indexvLIquZIOLmMeta?.path ?? "/nl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___pl",
    path: indexvLIquZIOLmMeta?.path ?? "/pl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___tr",
    path: indexvLIquZIOLmMeta?.path ?? "/tr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sv",
    path: indexvLIquZIOLmMeta?.path ?? "/sv/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___cs",
    path: indexvLIquZIOLmMeta?.path ?? "/cs/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___nb",
    path: indexvLIquZIOLmMeta?.path ?? "/nb/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ro",
    path: indexvLIquZIOLmMeta?.path ?? "/ro/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___el",
    path: indexvLIquZIOLmMeta?.path ?? "/el/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___da",
    path: indexvLIquZIOLmMeta?.path ?? "/da/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___uk",
    path: indexvLIquZIOLmMeta?.path ?? "/uk/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hr",
    path: indexvLIquZIOLmMeta?.path ?? "/hr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___ca",
    path: indexvLIquZIOLmMeta?.path ?? "/ca/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___hu",
    path: indexvLIquZIOLmMeta?.path ?? "/hu/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sk",
    path: indexvLIquZIOLmMeta?.path ?? "/sk/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___fi",
    path: indexvLIquZIOLmMeta?.path ?? "/fi/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sl",
    path: indexvLIquZIOLmMeta?.path ?? "/sl/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___bg",
    path: indexvLIquZIOLmMeta?.path ?? "/bg/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: indexvLIquZIOLmMeta?.name ?? "flight-to___sr",
    path: indexvLIquZIOLmMeta?.path ?? "/sr/flight/to",
    meta: indexvLIquZIOLmMeta || {},
    alias: indexvLIquZIOLmMeta?.alias || [],
    redirect: indexvLIquZIOLmMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/flight/to/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___en",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/en/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___id",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/id/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___tl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/tl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___zh",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/zh/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___zh-tw",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/zh-tw/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___th",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/th/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ja",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ja/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ms",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ms/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ko",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ko/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___vi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/vi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___it",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/it/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___de",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/de/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___es",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/es/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___fr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/fr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ru",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ru/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___bn",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/bn/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___pt",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/pt/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___km",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/km/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___nl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/nl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___pl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/pl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___tr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/tr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sv",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sv/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___cs",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/cs/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___nb",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/nb/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ro",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ro/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___el",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/el/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___da",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/da/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___uk",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/uk/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___ca",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/ca/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___hu",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/hu/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sk",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sk/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___fi",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/fi/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sl",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sl/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___bg",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/bg/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93XE7y2m9KOIMeta?.name ?? "guide-type___sr",
    path: _91type_93XE7y2m9KOIMeta?.path ?? "/sr/guide/:type()",
    meta: _91type_93XE7y2m9KOIMeta || {},
    alias: _91type_93XE7y2m9KOIMeta?.alias || [],
    redirect: _91type_93XE7y2m9KOIMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/[type].vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___en",
    path: index1bZqJZeZFHMeta?.path ?? "/en/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___id",
    path: index1bZqJZeZFHMeta?.path ?? "/id/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___tl",
    path: index1bZqJZeZFHMeta?.path ?? "/tl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___zh",
    path: index1bZqJZeZFHMeta?.path ?? "/zh/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___zh-tw",
    path: index1bZqJZeZFHMeta?.path ?? "/zh-tw/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___th",
    path: index1bZqJZeZFHMeta?.path ?? "/th/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ja",
    path: index1bZqJZeZFHMeta?.path ?? "/ja/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ms",
    path: index1bZqJZeZFHMeta?.path ?? "/ms/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ko",
    path: index1bZqJZeZFHMeta?.path ?? "/ko/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___vi",
    path: index1bZqJZeZFHMeta?.path ?? "/vi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___it",
    path: index1bZqJZeZFHMeta?.path ?? "/it/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___de",
    path: index1bZqJZeZFHMeta?.path ?? "/de/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___es",
    path: index1bZqJZeZFHMeta?.path ?? "/es/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hi",
    path: index1bZqJZeZFHMeta?.path ?? "/hi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___fr",
    path: index1bZqJZeZFHMeta?.path ?? "/fr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ru",
    path: index1bZqJZeZFHMeta?.path ?? "/ru/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___bn",
    path: index1bZqJZeZFHMeta?.path ?? "/bn/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___pt",
    path: index1bZqJZeZFHMeta?.path ?? "/pt/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___km",
    path: index1bZqJZeZFHMeta?.path ?? "/km/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___nl",
    path: index1bZqJZeZFHMeta?.path ?? "/nl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___pl",
    path: index1bZqJZeZFHMeta?.path ?? "/pl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___tr",
    path: index1bZqJZeZFHMeta?.path ?? "/tr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sv",
    path: index1bZqJZeZFHMeta?.path ?? "/sv/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___cs",
    path: index1bZqJZeZFHMeta?.path ?? "/cs/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___nb",
    path: index1bZqJZeZFHMeta?.path ?? "/nb/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ro",
    path: index1bZqJZeZFHMeta?.path ?? "/ro/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___el",
    path: index1bZqJZeZFHMeta?.path ?? "/el/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___da",
    path: index1bZqJZeZFHMeta?.path ?? "/da/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___uk",
    path: index1bZqJZeZFHMeta?.path ?? "/uk/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hr",
    path: index1bZqJZeZFHMeta?.path ?? "/hr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___ca",
    path: index1bZqJZeZFHMeta?.path ?? "/ca/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___hu",
    path: index1bZqJZeZFHMeta?.path ?? "/hu/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sk",
    path: index1bZqJZeZFHMeta?.path ?? "/sk/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___fi",
    path: index1bZqJZeZFHMeta?.path ?? "/fi/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sl",
    path: index1bZqJZeZFHMeta?.path ?? "/sl/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___bg",
    path: index1bZqJZeZFHMeta?.path ?? "/bg/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: index1bZqJZeZFHMeta?.name ?? "guide___sr",
    path: index1bZqJZeZFHMeta?.path ?? "/sr/guide",
    meta: index1bZqJZeZFHMeta || {},
    alias: index1bZqJZeZFHMeta?.alias || [],
    redirect: index1bZqJZeZFHMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___en",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/en/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___id",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/id/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___tl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/tl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___zh",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/zh/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___zh-tw",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/zh-tw/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___th",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/th/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ja",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ja/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ms",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ms/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ko",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ko/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___vi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/vi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___it",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/it/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___de",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/de/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___es",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/es/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___fr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/fr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ru",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ru/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___bn",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/bn/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___pt",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/pt/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___km",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/km/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___nl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/nl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___pl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/pl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___tr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/tr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sv",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sv/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___cs",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/cs/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___nb",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/nb/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ro",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ro/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___el",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/el/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___da",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/da/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___uk",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/uk/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___ca",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/ca/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___hu",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/hu/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sk",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sk/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___fi",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/fi/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sl",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sl/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___bg",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/bg/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pDTNvFP9uJMeta?.name ?? "hotel-slug___sr",
    path: _91slug_93pDTNvFP9uJMeta?.path ?? "/sr/hotel/:slug()",
    meta: _91slug_93pDTNvFP9uJMeta || {},
    alias: _91slug_93pDTNvFP9uJMeta?.alias || [],
    redirect: _91slug_93pDTNvFP9uJMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___en",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/en/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___id",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/id/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___tl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/tl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___zh",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/zh/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___zh-tw",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/zh-tw/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___th",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/th/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ja",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ja/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ms",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ms/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ko",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ko/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___vi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/vi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___it",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/it/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___de",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/de/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___es",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/es/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___fr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/fr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ru",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ru/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___bn",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/bn/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___pt",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/pt/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___km",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/km/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___nl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/nl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___pl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/pl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___tr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/tr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sv",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sv/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___cs",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/cs/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___nb",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/nb/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ro",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ro/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___el",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/el/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___da",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/da/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___uk",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/uk/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___ca",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/ca/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___hu",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/hu/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sk",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sk/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___fi",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/fi/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sl",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sl/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___bg",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/bg/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikH2wA01dLMeta?.name ?? "hotel-airport-slug___sr",
    path: _91slug_93ikH2wA01dLMeta?.path ?? "/sr/hotel/airport/:slug()",
    meta: _91slug_93ikH2wA01dLMeta || {},
    alias: _91slug_93ikH2wA01dLMeta?.alias || [],
    redirect: _91slug_93ikH2wA01dLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/airport/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___en",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/en/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___id",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/id/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___tl",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/tl/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___zh",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/zh/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___zh-tw",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/zh-tw/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___th",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/th/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ja",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ja/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ms",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ms/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ko",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ko/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___vi",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/vi/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___it",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/it/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___de",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/de/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___es",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/es/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___hi",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/hi/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___fr",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/fr/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ru",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ru/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___bn",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/bn/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___pt",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/pt/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___km",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/km/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___nl",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/nl/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___pl",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/pl/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___tr",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/tr/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___sv",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/sv/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___cs",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/cs/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___nb",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/nb/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ro",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ro/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___el",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/el/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___da",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/da/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___uk",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/uk/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___hr",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/hr/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___ca",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/ca/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___hu",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/hu/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___sk",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/sk/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___fi",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/fi/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___sl",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/sl/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___bg",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/bg/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starbDHMbh0DQFMeta?.name ?? "hotel-city-slug_city-slug_star-star___sr",
    path: _91slug_star_93_45starbDHMbh0DQFMeta?.path ?? "/sr/hotel/city/:slug_city()/:slug_star()-star",
    meta: _91slug_star_93_45starbDHMbh0DQFMeta || {},
    alias: _91slug_star_93_45starbDHMbh0DQFMeta?.alias || [],
    redirect: _91slug_star_93_45starbDHMbh0DQFMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___en",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/en/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___id",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/id/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___tl",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/tl/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___zh",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/zh/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___zh-tw",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/zh-tw/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___th",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/th/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ja",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ja/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ms",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ms/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ko",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ko/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___vi",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/vi/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___it",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/it/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___de",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/de/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___es",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/es/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___hi",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/hi/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___fr",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/fr/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ru",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ru/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___bn",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/bn/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___pt",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/pt/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___km",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/km/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___nl",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/nl/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___pl",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/pl/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___tr",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/tr/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___sv",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/sv/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___cs",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/cs/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___nb",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/nb/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ro",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ro/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___el",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/el/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___da",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/da/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___uk",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/uk/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___hr",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/hr/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___ca",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/ca/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___hu",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/hu/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___sk",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/sk/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___fi",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/fi/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___sl",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/sl/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___bg",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/bg/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93sQgbkerZ7pMeta?.name ?? "hotel-city-slug_city-facility-slug_facility___sr",
    path: facility_45_91slug_facility_93sQgbkerZ7pMeta?.path ?? "/sr/hotel/city/:slug_city()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93sQgbkerZ7pMeta || {},
    alias: facility_45_91slug_facility_93sQgbkerZ7pMeta?.alias || [],
    redirect: facility_45_91slug_facility_93sQgbkerZ7pMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___en",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/en/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___id",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/id/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___tl",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/tl/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___zh",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/zh/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___zh-tw",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/zh-tw/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___th",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/th/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ja",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ja/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ms",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ms/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ko",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ko/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___vi",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/vi/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___it",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/it/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___de",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/de/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___es",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/es/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___hi",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/hi/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___fr",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/fr/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ru",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ru/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___bn",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/bn/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___pt",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/pt/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___km",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/km/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___nl",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/nl/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___pl",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/pl/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___tr",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/tr/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___sv",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/sv/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___cs",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/cs/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___nb",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/nb/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ro",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ro/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___el",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/el/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___da",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/da/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___uk",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/uk/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___hr",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/hr/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___ca",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/ca/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___hu",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/hu/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___sk",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/sk/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___fi",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/fi/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___sl",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/sl/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___bg",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/bg/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: group_45_91slug_chain_936rramvYVj4Meta?.name ?? "hotel-city-slug_city-group-slug_chain___sr",
    path: group_45_91slug_chain_936rramvYVj4Meta?.path ?? "/sr/hotel/city/:slug_city()/group-:slug_chain()",
    meta: group_45_91slug_chain_936rramvYVj4Meta || {},
    alias: group_45_91slug_chain_936rramvYVj4Meta?.alias || [],
    redirect: group_45_91slug_chain_936rramvYVj4Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/group-[slug_chain].vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___en",
    path: indexvYoauhrF1EMeta?.path ?? "/en/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___id",
    path: indexvYoauhrF1EMeta?.path ?? "/id/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___tl",
    path: indexvYoauhrF1EMeta?.path ?? "/tl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___zh",
    path: indexvYoauhrF1EMeta?.path ?? "/zh/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___zh-tw",
    path: indexvYoauhrF1EMeta?.path ?? "/zh-tw/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___th",
    path: indexvYoauhrF1EMeta?.path ?? "/th/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ja",
    path: indexvYoauhrF1EMeta?.path ?? "/ja/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ms",
    path: indexvYoauhrF1EMeta?.path ?? "/ms/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ko",
    path: indexvYoauhrF1EMeta?.path ?? "/ko/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___vi",
    path: indexvYoauhrF1EMeta?.path ?? "/vi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___it",
    path: indexvYoauhrF1EMeta?.path ?? "/it/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___de",
    path: indexvYoauhrF1EMeta?.path ?? "/de/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___es",
    path: indexvYoauhrF1EMeta?.path ?? "/es/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hi",
    path: indexvYoauhrF1EMeta?.path ?? "/hi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___fr",
    path: indexvYoauhrF1EMeta?.path ?? "/fr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ru",
    path: indexvYoauhrF1EMeta?.path ?? "/ru/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___bn",
    path: indexvYoauhrF1EMeta?.path ?? "/bn/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___pt",
    path: indexvYoauhrF1EMeta?.path ?? "/pt/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___km",
    path: indexvYoauhrF1EMeta?.path ?? "/km/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___nl",
    path: indexvYoauhrF1EMeta?.path ?? "/nl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___pl",
    path: indexvYoauhrF1EMeta?.path ?? "/pl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___tr",
    path: indexvYoauhrF1EMeta?.path ?? "/tr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sv",
    path: indexvYoauhrF1EMeta?.path ?? "/sv/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___cs",
    path: indexvYoauhrF1EMeta?.path ?? "/cs/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___nb",
    path: indexvYoauhrF1EMeta?.path ?? "/nb/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ro",
    path: indexvYoauhrF1EMeta?.path ?? "/ro/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___el",
    path: indexvYoauhrF1EMeta?.path ?? "/el/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___da",
    path: indexvYoauhrF1EMeta?.path ?? "/da/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___uk",
    path: indexvYoauhrF1EMeta?.path ?? "/uk/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hr",
    path: indexvYoauhrF1EMeta?.path ?? "/hr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___ca",
    path: indexvYoauhrF1EMeta?.path ?? "/ca/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___hu",
    path: indexvYoauhrF1EMeta?.path ?? "/hu/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sk",
    path: indexvYoauhrF1EMeta?.path ?? "/sk/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___fi",
    path: indexvYoauhrF1EMeta?.path ?? "/fi/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sl",
    path: indexvYoauhrF1EMeta?.path ?? "/sl/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___bg",
    path: indexvYoauhrF1EMeta?.path ?? "/bg/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvYoauhrF1EMeta?.name ?? "hotel-city-slug_city___sr",
    path: indexvYoauhrF1EMeta?.path ?? "/sr/hotel/city/:slug_city()",
    meta: indexvYoauhrF1EMeta || {},
    alias: indexvYoauhrF1EMeta?.alias || [],
    redirect: indexvYoauhrF1EMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/index.vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___en",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/en/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___id",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/id/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___tl",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/tl/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___zh",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/zh/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___zh-tw",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/zh-tw/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___th",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/th/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ja",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ja/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ms",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ms/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ko",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ko/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___vi",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/vi/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___it",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/it/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___de",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/de/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___es",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/es/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___hi",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/hi/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___fr",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/fr/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ru",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ru/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___bn",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/bn/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___pt",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/pt/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___km",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/km/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___nl",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/nl/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___pl",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/pl/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___tr",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/tr/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___sv",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/sv/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___cs",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/cs/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___nb",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/nb/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ro",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ro/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___el",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/el/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___da",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/da/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___uk",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/uk/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___hr",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/hr/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___ca",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/ca/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___hu",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/hu/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___sk",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/sk/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___fi",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/fi/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___sl",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/sl/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___bg",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/bg/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_936YzOssuta0Meta?.name ?? "hotel-city-slug_city-type-slug_type___sr",
    path: type_45_91slug_type_936YzOssuta0Meta?.path ?? "/sr/hotel/city/:slug_city()/type-:slug_type()",
    meta: type_45_91slug_type_936YzOssuta0Meta || {},
    alias: type_45_91slug_type_936YzOssuta0Meta?.alias || [],
    redirect: type_45_91slug_type_936YzOssuta0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/city/[slug_city]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___en",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/en/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___id",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/id/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___tl",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/tl/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___zh",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/zh/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___zh-tw",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/zh-tw/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___th",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/th/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ja",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ja/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ms",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ms/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ko",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ko/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___vi",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/vi/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___it",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/it/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___de",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/de/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___es",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/es/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___hi",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/hi/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___fr",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/fr/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ru",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ru/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___bn",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/bn/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___pt",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/pt/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___km",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/km/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___nl",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/nl/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___pl",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/pl/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___tr",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/tr/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___sv",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/sv/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___cs",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/cs/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___nb",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/nb/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ro",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ro/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___el",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/el/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___da",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/da/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___uk",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/uk/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___hr",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/hr/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___ca",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/ca/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___hu",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/hu/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___sk",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/sk/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___fi",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/fi/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___sl",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/sl/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___bg",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/bg/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starv2hnZzYyDxMeta?.name ?? "hotel-country-slug_country-slug_star-star___sr",
    path: _91slug_star_93_45starv2hnZzYyDxMeta?.path ?? "/sr/hotel/country/:slug_country()/:slug_star()-star",
    meta: _91slug_star_93_45starv2hnZzYyDxMeta || {},
    alias: _91slug_star_93_45starv2hnZzYyDxMeta?.alias || [],
    redirect: _91slug_star_93_45starv2hnZzYyDxMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___en",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/en/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___id",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/id/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___tl",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/tl/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___zh",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/zh/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___zh-tw",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/zh-tw/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___th",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/th/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ja",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ja/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ms",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ms/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ko",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ko/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___vi",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/vi/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___it",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/it/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___de",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/de/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___es",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/es/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___hi",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/hi/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___fr",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/fr/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ru",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ru/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___bn",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/bn/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___pt",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/pt/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___km",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/km/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___nl",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/nl/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___pl",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/pl/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___tr",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/tr/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___sv",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/sv/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___cs",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/cs/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___nb",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/nb/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ro",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ro/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___el",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/el/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___da",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/da/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___uk",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/uk/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___hr",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/hr/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___ca",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/ca/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___hu",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/hu/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___sk",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/sk/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___fi",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/fi/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___sl",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/sl/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___bg",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/bg/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93ebRGcBcCNnMeta?.name ?? "hotel-country-slug_country-facility-slug_facility___sr",
    path: facility_45_91slug_facility_93ebRGcBcCNnMeta?.path ?? "/sr/hotel/country/:slug_country()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93ebRGcBcCNnMeta || {},
    alias: facility_45_91slug_facility_93ebRGcBcCNnMeta?.alias || [],
    redirect: facility_45_91slug_facility_93ebRGcBcCNnMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___en",
    path: index3hHlllgPzLMeta?.path ?? "/en/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___id",
    path: index3hHlllgPzLMeta?.path ?? "/id/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___tl",
    path: index3hHlllgPzLMeta?.path ?? "/tl/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___zh",
    path: index3hHlllgPzLMeta?.path ?? "/zh/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___zh-tw",
    path: index3hHlllgPzLMeta?.path ?? "/zh-tw/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___th",
    path: index3hHlllgPzLMeta?.path ?? "/th/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ja",
    path: index3hHlllgPzLMeta?.path ?? "/ja/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ms",
    path: index3hHlllgPzLMeta?.path ?? "/ms/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ko",
    path: index3hHlllgPzLMeta?.path ?? "/ko/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___vi",
    path: index3hHlllgPzLMeta?.path ?? "/vi/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___it",
    path: index3hHlllgPzLMeta?.path ?? "/it/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___de",
    path: index3hHlllgPzLMeta?.path ?? "/de/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___es",
    path: index3hHlllgPzLMeta?.path ?? "/es/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___hi",
    path: index3hHlllgPzLMeta?.path ?? "/hi/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___fr",
    path: index3hHlllgPzLMeta?.path ?? "/fr/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ru",
    path: index3hHlllgPzLMeta?.path ?? "/ru/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___bn",
    path: index3hHlllgPzLMeta?.path ?? "/bn/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___pt",
    path: index3hHlllgPzLMeta?.path ?? "/pt/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___km",
    path: index3hHlllgPzLMeta?.path ?? "/km/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___nl",
    path: index3hHlllgPzLMeta?.path ?? "/nl/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___pl",
    path: index3hHlllgPzLMeta?.path ?? "/pl/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___tr",
    path: index3hHlllgPzLMeta?.path ?? "/tr/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___sv",
    path: index3hHlllgPzLMeta?.path ?? "/sv/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___cs",
    path: index3hHlllgPzLMeta?.path ?? "/cs/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___nb",
    path: index3hHlllgPzLMeta?.path ?? "/nb/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ro",
    path: index3hHlllgPzLMeta?.path ?? "/ro/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___el",
    path: index3hHlllgPzLMeta?.path ?? "/el/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___da",
    path: index3hHlllgPzLMeta?.path ?? "/da/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___uk",
    path: index3hHlllgPzLMeta?.path ?? "/uk/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___hr",
    path: index3hHlllgPzLMeta?.path ?? "/hr/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___ca",
    path: index3hHlllgPzLMeta?.path ?? "/ca/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___hu",
    path: index3hHlllgPzLMeta?.path ?? "/hu/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___sk",
    path: index3hHlllgPzLMeta?.path ?? "/sk/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___fi",
    path: index3hHlllgPzLMeta?.path ?? "/fi/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___sl",
    path: index3hHlllgPzLMeta?.path ?? "/sl/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___bg",
    path: index3hHlllgPzLMeta?.path ?? "/bg/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: index3hHlllgPzLMeta?.name ?? "hotel-country-slug_country___sr",
    path: index3hHlllgPzLMeta?.path ?? "/sr/hotel/country/:slug_country()",
    meta: index3hHlllgPzLMeta || {},
    alias: index3hHlllgPzLMeta?.alias || [],
    redirect: index3hHlllgPzLMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/index.vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___en",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/en/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___id",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/id/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___tl",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/tl/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___zh",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/zh/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___zh-tw",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/zh-tw/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___th",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/th/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ja",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ja/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ms",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ms/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ko",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ko/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___vi",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/vi/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___it",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/it/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___de",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/de/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___es",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/es/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___hi",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/hi/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___fr",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/fr/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ru",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ru/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___bn",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/bn/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___pt",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/pt/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___km",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/km/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___nl",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/nl/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___pl",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/pl/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___tr",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/tr/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___sv",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/sv/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___cs",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/cs/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___nb",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/nb/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ro",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ro/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___el",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/el/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___da",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/da/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___uk",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/uk/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___hr",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/hr/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___ca",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/ca/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___hu",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/hu/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___sk",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/sk/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___fi",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/fi/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___sl",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/sl/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___bg",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/bg/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93rRRcRD5d8hMeta?.name ?? "hotel-country-slug_country-type-slug_type___sr",
    path: type_45_91slug_type_93rRRcRD5d8hMeta?.path ?? "/sr/hotel/country/:slug_country()/type-:slug_type()",
    meta: type_45_91slug_type_93rRRcRD5d8hMeta || {},
    alias: type_45_91slug_type_93rRRcRD5d8hMeta?.alias || [],
    redirect: type_45_91slug_type_93rRRcRD5d8hMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/country/[slug_country]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___en",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/en/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___id",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/id/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___tl",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/tl/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___zh",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/zh/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___zh-tw",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/zh-tw/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___th",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/th/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ja",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ja/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ms",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ms/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ko",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ko/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___vi",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/vi/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___it",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/it/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___de",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/de/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___es",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/es/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___hi",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/hi/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___fr",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/fr/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ru",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ru/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___bn",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/bn/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___pt",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/pt/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___km",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/km/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___nl",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/nl/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___pl",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/pl/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___tr",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/tr/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___sv",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/sv/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___cs",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/cs/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___nb",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/nb/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ro",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ro/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___el",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/el/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___da",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/da/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___uk",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/uk/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___hr",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/hr/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___ca",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/ca/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___hu",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/hu/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___sk",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/sk/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___fi",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/fi/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___sl",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/sl/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___bg",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/bg/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45staroHx3roTXJfMeta?.name ?? "hotel-district-slug_district-slug_star-star___sr",
    path: _91slug_star_93_45staroHx3roTXJfMeta?.path ?? "/sr/hotel/district/:slug_district()/:slug_star()-star",
    meta: _91slug_star_93_45staroHx3roTXJfMeta || {},
    alias: _91slug_star_93_45staroHx3roTXJfMeta?.alias || [],
    redirect: _91slug_star_93_45staroHx3roTXJfMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___en",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/en/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___id",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/id/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___tl",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/tl/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___zh",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/zh/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___zh-tw",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/zh-tw/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___th",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/th/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ja",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ja/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ms",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ms/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ko",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ko/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___vi",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/vi/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___it",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/it/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___de",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/de/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___es",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/es/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___hi",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/hi/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___fr",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/fr/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ru",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ru/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___bn",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/bn/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___pt",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/pt/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___km",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/km/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___nl",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/nl/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___pl",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/pl/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___tr",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/tr/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___sv",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/sv/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___cs",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/cs/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___nb",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/nb/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ro",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ro/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___el",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/el/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___da",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/da/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___uk",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/uk/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___hr",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/hr/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___ca",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/ca/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___hu",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/hu/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___sk",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/sk/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___fi",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/fi/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___sl",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/sl/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___bg",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/bg/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_93wrm7VMFwUZMeta?.name ?? "hotel-district-slug_district-facility-slug_facility___sr",
    path: facility_45_91slug_facility_93wrm7VMFwUZMeta?.path ?? "/sr/hotel/district/:slug_district()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_93wrm7VMFwUZMeta || {},
    alias: facility_45_91slug_facility_93wrm7VMFwUZMeta?.alias || [],
    redirect: facility_45_91slug_facility_93wrm7VMFwUZMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___en",
    path: indexqWTuit0DAgMeta?.path ?? "/en/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___id",
    path: indexqWTuit0DAgMeta?.path ?? "/id/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___tl",
    path: indexqWTuit0DAgMeta?.path ?? "/tl/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___zh",
    path: indexqWTuit0DAgMeta?.path ?? "/zh/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___zh-tw",
    path: indexqWTuit0DAgMeta?.path ?? "/zh-tw/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___th",
    path: indexqWTuit0DAgMeta?.path ?? "/th/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ja",
    path: indexqWTuit0DAgMeta?.path ?? "/ja/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ms",
    path: indexqWTuit0DAgMeta?.path ?? "/ms/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ko",
    path: indexqWTuit0DAgMeta?.path ?? "/ko/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___vi",
    path: indexqWTuit0DAgMeta?.path ?? "/vi/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___it",
    path: indexqWTuit0DAgMeta?.path ?? "/it/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___de",
    path: indexqWTuit0DAgMeta?.path ?? "/de/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___es",
    path: indexqWTuit0DAgMeta?.path ?? "/es/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___hi",
    path: indexqWTuit0DAgMeta?.path ?? "/hi/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___fr",
    path: indexqWTuit0DAgMeta?.path ?? "/fr/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ru",
    path: indexqWTuit0DAgMeta?.path ?? "/ru/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___bn",
    path: indexqWTuit0DAgMeta?.path ?? "/bn/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___pt",
    path: indexqWTuit0DAgMeta?.path ?? "/pt/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___km",
    path: indexqWTuit0DAgMeta?.path ?? "/km/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___nl",
    path: indexqWTuit0DAgMeta?.path ?? "/nl/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___pl",
    path: indexqWTuit0DAgMeta?.path ?? "/pl/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___tr",
    path: indexqWTuit0DAgMeta?.path ?? "/tr/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___sv",
    path: indexqWTuit0DAgMeta?.path ?? "/sv/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___cs",
    path: indexqWTuit0DAgMeta?.path ?? "/cs/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___nb",
    path: indexqWTuit0DAgMeta?.path ?? "/nb/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ro",
    path: indexqWTuit0DAgMeta?.path ?? "/ro/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___el",
    path: indexqWTuit0DAgMeta?.path ?? "/el/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___da",
    path: indexqWTuit0DAgMeta?.path ?? "/da/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___uk",
    path: indexqWTuit0DAgMeta?.path ?? "/uk/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___hr",
    path: indexqWTuit0DAgMeta?.path ?? "/hr/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___ca",
    path: indexqWTuit0DAgMeta?.path ?? "/ca/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___hu",
    path: indexqWTuit0DAgMeta?.path ?? "/hu/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___sk",
    path: indexqWTuit0DAgMeta?.path ?? "/sk/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___fi",
    path: indexqWTuit0DAgMeta?.path ?? "/fi/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___sl",
    path: indexqWTuit0DAgMeta?.path ?? "/sl/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___bg",
    path: indexqWTuit0DAgMeta?.path ?? "/bg/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqWTuit0DAgMeta?.name ?? "hotel-district-slug_district___sr",
    path: indexqWTuit0DAgMeta?.path ?? "/sr/hotel/district/:slug_district()",
    meta: indexqWTuit0DAgMeta || {},
    alias: indexqWTuit0DAgMeta?.alias || [],
    redirect: indexqWTuit0DAgMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/index.vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___en",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/en/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___id",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/id/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___tl",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/tl/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___zh",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/zh/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___zh-tw",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/zh-tw/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___th",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/th/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ja",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ja/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ms",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ms/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ko",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ko/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___vi",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/vi/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___it",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/it/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___de",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/de/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___es",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/es/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___hi",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/hi/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___fr",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/fr/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ru",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ru/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___bn",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/bn/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___pt",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/pt/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___km",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/km/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___nl",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/nl/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___pl",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/pl/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___tr",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/tr/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___sv",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/sv/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___cs",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/cs/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___nb",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/nb/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ro",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ro/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___el",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/el/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___da",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/da/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___uk",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/uk/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___hr",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/hr/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___ca",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/ca/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___hu",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/hu/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___sk",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/sk/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___fi",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/fi/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___sl",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/sl/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___bg",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/bg/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93OQbdCwCgA2Meta?.name ?? "hotel-district-slug_district-type-slug_type___sr",
    path: type_45_91slug_type_93OQbdCwCgA2Meta?.path ?? "/sr/hotel/district/:slug_district()/type-:slug_type()",
    meta: type_45_91slug_type_93OQbdCwCgA2Meta || {},
    alias: type_45_91slug_type_93OQbdCwCgA2Meta?.alias || [],
    redirect: type_45_91slug_type_93OQbdCwCgA2Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/district/[slug_district]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___en",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/en/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___id",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/id/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___tl",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/tl/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___zh",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/zh/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___zh-tw",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/zh-tw/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___th",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/th/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ja",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ja/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ms",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ms/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ko",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ko/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___vi",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/vi/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___it",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/it/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___de",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/de/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___es",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/es/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___hi",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/hi/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___fr",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/fr/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ru",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ru/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___bn",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/bn/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___pt",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/pt/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___km",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/km/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___nl",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/nl/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___pl",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/pl/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___tr",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/tr/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___sv",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/sv/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___cs",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/cs/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___nb",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/nb/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ro",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ro/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___el",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/el/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___da",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/da/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___uk",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/uk/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___hr",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/hr/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___ca",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/ca/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___hu",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/hu/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___sk",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/sk/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___fi",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/fi/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___sl",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/sl/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___bg",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/bg/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_landmark_93JB6VYDXmw1Meta?.name ?? "hotel-landmark-slug_landmark___sr",
    path: _91slug_landmark_93JB6VYDXmw1Meta?.path ?? "/sr/hotel/landmark/:slug_landmark()",
    meta: _91slug_landmark_93JB6VYDXmw1Meta || {},
    alias: _91slug_landmark_93JB6VYDXmw1Meta?.alias || [],
    redirect: _91slug_landmark_93JB6VYDXmw1Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/landmark/[slug_landmark].vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___en",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/en/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___id",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/id/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___tl",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/tl/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___zh",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/zh/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___zh-tw",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/zh-tw/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___th",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/th/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ja",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ja/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ms",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ms/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ko",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ko/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___vi",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/vi/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___it",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/it/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___de",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/de/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___es",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/es/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___hi",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/hi/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___fr",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/fr/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ru",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ru/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___bn",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/bn/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___pt",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/pt/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___km",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/km/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___nl",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/nl/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___pl",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/pl/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___tr",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/tr/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___sv",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/sv/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___cs",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/cs/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___nb",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/nb/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ro",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ro/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___el",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/el/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___da",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/da/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___uk",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/uk/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___hr",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/hr/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___ca",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/ca/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___hu",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/hu/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___sk",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/sk/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___fi",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/fi/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___sl",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/sl/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___bg",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/bg/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: _91slug_star_93_45starw5vBPjKgZYMeta?.name ?? "hotel-region-slug_region-slug_star-star___sr",
    path: _91slug_star_93_45starw5vBPjKgZYMeta?.path ?? "/sr/hotel/region/:slug_region()/:slug_star()-star",
    meta: _91slug_star_93_45starw5vBPjKgZYMeta || {},
    alias: _91slug_star_93_45starw5vBPjKgZYMeta?.alias || [],
    redirect: _91slug_star_93_45starw5vBPjKgZYMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/[slug_star]-star.vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___en",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/en/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___id",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/id/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___tl",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/tl/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___zh",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/zh/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___zh-tw",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/zh-tw/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___th",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/th/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ja",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ja/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ms",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ms/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ko",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ko/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___vi",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/vi/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___it",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/it/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___de",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/de/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___es",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/es/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___hi",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/hi/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___fr",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/fr/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ru",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ru/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___bn",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/bn/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___pt",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/pt/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___km",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/km/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___nl",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/nl/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___pl",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/pl/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___tr",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/tr/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___sv",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/sv/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___cs",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/cs/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___nb",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/nb/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ro",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ro/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___el",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/el/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___da",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/da/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___uk",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/uk/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___hr",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/hr/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___ca",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/ca/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___hu",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/hu/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___sk",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/sk/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___fi",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/fi/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___sl",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/sl/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___bg",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/bg/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: facility_45_91slug_facility_937aymqOEvGcMeta?.name ?? "hotel-region-slug_region-facility-slug_facility___sr",
    path: facility_45_91slug_facility_937aymqOEvGcMeta?.path ?? "/sr/hotel/region/:slug_region()/facility-:slug_facility()",
    meta: facility_45_91slug_facility_937aymqOEvGcMeta || {},
    alias: facility_45_91slug_facility_937aymqOEvGcMeta?.alias || [],
    redirect: facility_45_91slug_facility_937aymqOEvGcMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/facility-[slug_facility].vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___en",
    path: index6ym2b9qFZ0Meta?.path ?? "/en/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___id",
    path: index6ym2b9qFZ0Meta?.path ?? "/id/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___tl",
    path: index6ym2b9qFZ0Meta?.path ?? "/tl/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___zh",
    path: index6ym2b9qFZ0Meta?.path ?? "/zh/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___zh-tw",
    path: index6ym2b9qFZ0Meta?.path ?? "/zh-tw/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___th",
    path: index6ym2b9qFZ0Meta?.path ?? "/th/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ja",
    path: index6ym2b9qFZ0Meta?.path ?? "/ja/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ms",
    path: index6ym2b9qFZ0Meta?.path ?? "/ms/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ko",
    path: index6ym2b9qFZ0Meta?.path ?? "/ko/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___vi",
    path: index6ym2b9qFZ0Meta?.path ?? "/vi/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___it",
    path: index6ym2b9qFZ0Meta?.path ?? "/it/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___de",
    path: index6ym2b9qFZ0Meta?.path ?? "/de/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___es",
    path: index6ym2b9qFZ0Meta?.path ?? "/es/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___hi",
    path: index6ym2b9qFZ0Meta?.path ?? "/hi/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___fr",
    path: index6ym2b9qFZ0Meta?.path ?? "/fr/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ru",
    path: index6ym2b9qFZ0Meta?.path ?? "/ru/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___bn",
    path: index6ym2b9qFZ0Meta?.path ?? "/bn/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___pt",
    path: index6ym2b9qFZ0Meta?.path ?? "/pt/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___km",
    path: index6ym2b9qFZ0Meta?.path ?? "/km/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___nl",
    path: index6ym2b9qFZ0Meta?.path ?? "/nl/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___pl",
    path: index6ym2b9qFZ0Meta?.path ?? "/pl/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___tr",
    path: index6ym2b9qFZ0Meta?.path ?? "/tr/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___sv",
    path: index6ym2b9qFZ0Meta?.path ?? "/sv/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___cs",
    path: index6ym2b9qFZ0Meta?.path ?? "/cs/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___nb",
    path: index6ym2b9qFZ0Meta?.path ?? "/nb/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ro",
    path: index6ym2b9qFZ0Meta?.path ?? "/ro/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___el",
    path: index6ym2b9qFZ0Meta?.path ?? "/el/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___da",
    path: index6ym2b9qFZ0Meta?.path ?? "/da/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___uk",
    path: index6ym2b9qFZ0Meta?.path ?? "/uk/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___hr",
    path: index6ym2b9qFZ0Meta?.path ?? "/hr/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___ca",
    path: index6ym2b9qFZ0Meta?.path ?? "/ca/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___hu",
    path: index6ym2b9qFZ0Meta?.path ?? "/hu/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___sk",
    path: index6ym2b9qFZ0Meta?.path ?? "/sk/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___fi",
    path: index6ym2b9qFZ0Meta?.path ?? "/fi/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___sl",
    path: index6ym2b9qFZ0Meta?.path ?? "/sl/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___bg",
    path: index6ym2b9qFZ0Meta?.path ?? "/bg/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: index6ym2b9qFZ0Meta?.name ?? "hotel-region-slug_region___sr",
    path: index6ym2b9qFZ0Meta?.path ?? "/sr/hotel/region/:slug_region()",
    meta: index6ym2b9qFZ0Meta || {},
    alias: index6ym2b9qFZ0Meta?.alias || [],
    redirect: index6ym2b9qFZ0Meta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/index.vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___en",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/en/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___id",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/id/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___tl",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/tl/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___zh",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/zh/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___zh-tw",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/zh-tw/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___th",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/th/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ja",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ja/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ms",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ms/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ko",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ko/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___vi",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/vi/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___it",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/it/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___de",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/de/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___es",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/es/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___hi",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/hi/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___fr",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/fr/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ru",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ru/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___bn",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/bn/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___pt",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/pt/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___km",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/km/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___nl",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/nl/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___pl",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/pl/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___tr",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/tr/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___sv",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/sv/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___cs",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/cs/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___nb",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/nb/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ro",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ro/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___el",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/el/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___da",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/da/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___uk",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/uk/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___hr",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/hr/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___ca",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/ca/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___hu",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/hu/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___sk",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/sk/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___fi",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/fi/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___sl",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/sl/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___bg",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/bg/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  },
  {
    name: type_45_91slug_type_93cxOSIJtP0GMeta?.name ?? "hotel-region-slug_region-type-slug_type___sr",
    path: type_45_91slug_type_93cxOSIJtP0GMeta?.path ?? "/sr/hotel/region/:slug_region()/type-:slug_type()",
    meta: type_45_91slug_type_93cxOSIJtP0GMeta || {},
    alias: type_45_91slug_type_93cxOSIJtP0GMeta?.alias || [],
    redirect: type_45_91slug_type_93cxOSIJtP0GMeta?.redirect || undefined,
    component: () => import("/builds/apzfront/forerunner-next/apps/blaze/pages/hotel/region/[slug_region]/type-[slug_type].vue").then(m => m.default || m)
  }
]