import revive_payload_client_2j1huXHBiQ from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dZn3AaZndY from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Gtiw2N2JNN from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yekCA8YiRN from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_MROw66pKpN from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_F10RLwfnTj from "/builds/apzfront/forerunner-next/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.4_typescript@5.3.3_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _64nuxt_scripts_D34jXE4xNh from "/builds/apzfront/forerunner-next/apps/blaze/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/builds/apzfront/forerunner-next/apps/blaze/.nuxt/components.plugin.mjs";
import prefetch_client_LYcA9m7UBB from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_AVrLS0PrT1 from "/builds/apzfront/forerunner-next/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_rkHGb0uklo from "/builds/apzfront/forerunner-next/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kbPfI04PQL from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_veJJSBEn9A from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_eslint@8.56.0_rollup@4.9_iitbzwwxncgdkdikyeaph6w5vm/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_cm6UD4HbOo from "/builds/apzfront/forerunner-next/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.9.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _1_device_tr8VktMdvj from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/1.device.ts";
import _2_auid_IDYO8phbiN from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/2.auid.ts";
import _3_apifront_M37q0xzQHk from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/3.apifront.ts";
import _4_user_config_rozmaElpAA from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/4.user-config.ts";
import _5_traffic_setup_dJy8HVYlc9 from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/5.traffic-setup.ts";
import auth_U3EIo0yEba from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/auth.ts";
import currency_jCOCY2Ze7e from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/currency.ts";
import date_0KJixH5oZ0 from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/date.ts";
import gtag_client_RSZwgfO6tY from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/gtag.client.ts";
import icon_b9DdzN6O8M from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/icon.ts";
import jitsu_ZrspwZkZWL from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/jitsu.ts";
import scroll_top_client_wsjUmYJpOL from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/scroll-top.client.ts";
import sentry_client_hSg8SgIULy from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/sentry.client.ts";
import yandex_metrika_client_EmHFLfrOfg from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/plugins/yandex-metrika.client.ts";
export default [
  revive_payload_client_2j1huXHBiQ,
  unhead_dZn3AaZndY,
  router_Gtiw2N2JNN,
  payload_client_yekCA8YiRN,
  check_outdated_build_client_MROw66pKpN,
  plugin_vue3_F10RLwfnTj,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LYcA9m7UBB,
  composition_AVrLS0PrT1,
  i18n_rkHGb0uklo,
  chunk_reload_client_kbPfI04PQL,
  cross_origin_prefetch_client_veJJSBEn9A,
  plugin_cm6UD4HbOo,
  _1_device_tr8VktMdvj,
  _2_auid_IDYO8phbiN,
  _3_apifront_M37q0xzQHk,
  _4_user_config_rozmaElpAA,
  _5_traffic_setup_dJy8HVYlc9,
  auth_U3EIo0yEba,
  currency_jCOCY2Ze7e,
  date_0KJixH5oZ0,
  gtag_client_RSZwgfO6tY,
  icon_b9DdzN6O8M,
  jitsu_ZrspwZkZWL,
  scroll_top_client_wsjUmYJpOL,
  sentry_client_hSg8SgIULy,
  yandex_metrika_client_EmHFLfrOfg
]